import { makeStyles } from '@material-ui/core/styles';
import React, {useState} from 'react';
import { useDispatch } from "react-redux";
import { Redirect } from 'react-router';
import axios from 'axios';
import { notification } from 'antd';
import isEmail from 'validator/lib/isEmail';
import {ServerConfig} from '../../ancient-finance/config'
import { updateLogin, updateWallet } from "../../state/application/actions";

const useStyles = makeStyles((theme) => ({
  form: {
    boxShadow: '1px 1px 1px 10px #0001',
    background: '#fff',
    borderRadius: '5px',
    padding: '50px 30px',
  },
  invalidInput: {
    color: "red"
  }
}));


const SERVER_URL = ServerConfig.url;

const RecoverPwdComponent = (props) => {
  const classes = useStyles();
  const [pwd, setPwd] = useState('')
  const [confirmPwd, setConfirmPwd] = useState('')
  const [validEmail, setValidEmail] = useState(true)
  const [sentEmail, setSentEmail] = useState(false)
  const [validPwd, setValidPwd] = useState(true)
  const [validConfirmPwd, setValidConfirmPwd] = useState(true)
  const { match: { params: { hash, referral }} } = props;
  const [verifyHash, setVerifyHash] = useState(hash ? hash : "")
  const dispatch = useDispatch();

  console.log('recover', hash)
  let credentialObj = JSON.parse(localStorage.getItem("login"))

  const isStrongPwd = (str) => {
    return (str.length < 8) ? false : true;
  }

  function toastMessage(msg, timeout) {
    notification.open({
        message: 'Alert',
        description: msg,
        duration: timeout
    });
    /*setMessage(msg);
    setShowMessage(true);
    setTimeout(() => {
        setShowMessage(false)
    }, timeout);*/
}

  const recoverPwd = (e) => {
    e.preventDefault();
    
    if (!pwd || !validPwd) {
      if (pwd !== "*Must be longer than 8 letters") {
        setPwd("*Please fill out this field")
      }
      setValidPwd(false)
      return;
    }

    if (!isStrongPwd(pwd)) {
      setPwd("*Must be longer than 8 letters")
      setValidPwd(false)
      return;
    }

    if (!confirmPwd || !validConfirmPwd) {
      if (confirmPwd !== "*Unmatched password") {
        setConfirmPwd("*Please fill out this field")
      }
      setValidConfirmPwd(false)
      return;
    }

    if (pwd !== confirmPwd) {
      setConfirmPwd("*Unmatched password")
      setValidConfirmPwd(false)
      return;
    }

    axios.post(SERVER_URL + "verify", {newPwd: pwd, randString: verifyHash})
    .then((res) => {
      if (res.data) {
        console.log(res.data)
        if (res.data.status) {
          localStorage.setItem('login', JSON.stringify(res.data.message))
          toastMessage("Password Reset Successfully", 1.2)
          //setLoginStatus(res.data.message.user.userName)
          dispatch(
              updateWallet({
                  wallet: "Connected"
              })
          )
          dispatch(
            updateLogin({
              userName: res.data.message.user.userName
            })
          )
          localStorage.setItem("login", JSON.stringify(res.data.message.user))
          localStorage.setItem('pwdLogin', 'connected')
        } else if (!res.data.status) {
          toastMessage(res.data.message, 1.2)
        }
      }
    }).catch((err) => {
      toastMessage("Login Failed", 1.2)
    });

    return;
  }

  const onFocusPwd = () => {
    if (!validPwd) {
      setValidPwd(true)
      setPwd("")
    }
  }

  const onFocusConfirmPwd = () => {
    if (!validConfirmPwd) {
      setValidConfirmPwd(true)
      setConfirmPwd("")
    }
  }

  return (
    <main>
      {(!credentialObj && !sentEmail) ? <section id='contact' className='contact section-padding'>
        <div className='container'>
          <div className='heading text-center'>
            <div
              className='animated'
              data-animation='fadeInUpShorter'
              data-animation-delay='0.3s'
            >
            </div>
          </div>

          <div className='row'>
            <div className='col-xl-6 col-md-12 mx-auto'>
              <form
                action='#'
                method='get'
                acceptCharset='utf-8'
                className={`text-center ${classes.form}`}
              >
                <h3
                  style={{ color: "#495057" }}
                >
                  Reset Password
                </h3>
                <br />
                <input
                  type={validPwd ? 'password' : 'edit'}
                  className={`form-control mb-4 animated ${!validPwd && classes.invalidInput}`}
                  data-animation='fadeInUpShorter'
                  data-animation-delay='0.3s'
                  name='userName'
                  placeholder='Password'
                  onFocus={onFocusPwd}
                  value={pwd}
                  onChange={(e) => {setPwd(e.target.value)}}
                  maxLength={25}
                />
                <input
                  type={validConfirmPwd ? 'password' : 'edit'}
                  className={`form-control mb-4 animated ${!validConfirmPwd && classes.invalidInput}`}
                  data-animation='fadeInUpShorter'
                  data-animation-delay='0.3s'
                  name='userName'
                  placeholder='Confirm Password'
                  onFocus={onFocusConfirmPwd}
                  value={confirmPwd}
                  onChange={(e) => {setConfirmPwd(e.target.value)}}
                  maxLength={25}
                />

                <button
                  type='submit'
                  className='btn btn-lg btn-gradient-purple animated'
                  data-animation='fadeInUpShorter'
                  data-animation-delay='0.3s'
                  style={{ boxShadow: '0 0 6px 0px #9e48cd' }}
                  onClick={recoverPwd}
                >
                  Reset
                </button>
              </form>
            </div>
          </div>
        </div>
      </section> :
      <Redirect to="/"></Redirect>}
    </main>
  );
};

export default RecoverPwdComponent;
