import React from 'react';
import PhoneVerifyComponent from '../../components/PhoneVerifyComponent';

const PhoneVerifyContainer = (props) => {
  return (
    <>
      <PhoneVerifyComponent {...props} />
    </>
  );
};

export default PhoneVerifyContainer;
