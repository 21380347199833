import { ChainId, DAppProvider } from '@usedapp/core';
import 'antd/dist/antd.css';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import CryptoFooterComponent from './components/CryptoFooterComponent';
import CryptoHeaderComponent from './components/CryptoHeaderComponent';
import CryptoPreloaderComponent from './components/CryptoPreloaderComponent';
import CryptoVerticalSocialComponent from './components/CryptoVerticalSocialComponent';
import Popups from './components/Popups';
import configs from './config';
import AncientFinanceProvider from './contexts/AncientFinanceProvider';
import ModalsProvider from './contexts/Modals';
import { RefreshContextProvider } from './contexts/RefreshContext';
import usePromptNetwork from './hooks/useNetworkPrompt';
import ContactPage from './pages/ContactPage';
import DashboardPage from './pages/DashboardPage';
import FaqPage from './pages/FaqPage';
import GalleryPage from './pages/GalleryPage';
import LandingPage from './pages/LandingPage';
import PrivatePage from './pages/PrivatePage';
import LeadershipPage from './pages/LeadershipPage';
import AccountPage from './pages/AccountPage';
import BonusPage from './pages/BonusPage';
import LoginPage from './pages/LoginPage';
import ForgotPwdPage from './pages/ForgotPwdPage'
import PhoneVerifyPage from './pages/PhoneVerifyPage'
import RecoverPwdPage from './pages/RecoverPwdPage'
import NewsDetailPage from './pages/NewsDetailPage';
import NewsPage from './pages/NewsPage';
import NewsReadyPage from './pages/NewsReadyPage';
import ProfilePage from './pages/ProfilePage';
import RegisterPage from './pages/RegisterPage';
import TeamPage from './pages/TeamPage';
import store from './state';
import Updaters from './state/Updaters';

declare global {
  interface Window {
    ethereum: any
  }
}

const App: React.FC = () => {
  // Clear localStorage for mobile users
  if (
    typeof localStorage.version_app === 'undefined' ||
    localStorage.version_app !== '1.0'
  ) {
    localStorage.clear();
    localStorage.setItem('connectorId', '');
    localStorage.setItem('version_app', '1.0');
  }

  //usePromptNetwork();

  return (
    //<Providers>
    <Provider store={store}>
      <CryptoPreloaderComponent />
      <Router>
        <CryptoVerticalSocialComponent />
        <CryptoHeaderComponent />
        <div className='content-wrapper'>
          <div className='content-body'>
            <Switch>
              <Route exact path='/' component={LandingPage} />
              {/*<Route exact path='/news' component={NewsPage} />
              <Route exact path='/news-detail' component={NewsDetailPage} />
              <Route exact path='/team' component={TeamPage} />
              <Route exact path='/faq' component={FaqPage} />
              <Route exact path='/contact' component={ContactPage} />*/}
              <Route exact path='/dashboard' component={DashboardPage} />
              <Route exact path='/register' component={RegisterPage} />
              <Route exact path='/register/:publicAddress' component={RegisterPage} />
              <Route exact path='/login' component={LoginPage} />
              <Route exact path='/Forgot' component={ForgotPwdPage} />
              <Route exact path='/phoneVerify' component={PhoneVerifyPage} />
              <Route exact path='/profile' component={ProfilePage} />
              <Route exact path='/bonus' component={BonusPage} />
              <Route exact path='/private' component={PrivatePage} />
              <Route exact path='/account' component={AccountPage} />
              <Route exact path='/leadership' component={LeadershipPage} />
              <Route exact path='/gallery' component={GalleryPage} />
              <Route exact path='/recoverPwd/:hash' component={RecoverPwdPage} />
              <Route path='/verify/:hash' component={LandingPage} />
              <Route path='/referral/:referral' component={RegisterPage} />
              <Route
                exact
                path='/news-ready-to-get-on-board'
                component={NewsReadyPage}
              />
            </Switch>
          </div>
        </div>
        <CryptoFooterComponent />
      </Router>
    </Provider>
    //</Providers>
  );
};

const config = {
  supportedChains: [ChainId.Ropsten],
  readOnlyChainId: ChainId.Ropsten,
  readOnlyUrls: {
    [ChainId.Ropsten]: configs.defaultProvider,
  },
};

const Providers: React.FC = ({ children }) => {
  return (
    <DAppProvider config={config}>
      <Provider store={store}>
        <Updaters />
        <RefreshContextProvider>
          <AncientFinanceProvider>
            <ModalsProvider>
              <>
                <Popups />
                {children}
              </>
            </ModalsProvider>
          </AncientFinanceProvider>
        </RefreshContextProvider>
      </Provider>
    </DAppProvider>
  );
};

export default App;
