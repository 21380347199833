import { ImageList, ImageListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Image1 from '../../assets/gallery/image-1.jpg';
import Image10 from '../../assets/gallery/image-10.jpg';
import Image11 from '../../assets/gallery/image-11.jpg';
import Image12 from '../../assets/gallery/image-12.jpg';
import Image13 from '../../assets/gallery/image-13.jpg';
import Image2 from '../../assets/gallery/image-2.jpg';
import Image3 from '../../assets/gallery/image-3.jpg';
import Image4 from '../../assets/gallery/image-4.jpg';
import Image5 from '../../assets/gallery/image-5.jpg';
import Image6 from '../../assets/gallery/image-6.jpg';
import Image7 from '../../assets/gallery/image-7.jpg';
import Image9 from '../../assets/gallery/image-9.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    padding: 10,
    borderRadius: 10,
  },
  imageList: {
    width: '100%',
    height: '100%',
  },
  imageListItem: {
    padding: '10px !important',
  },
}));

const itemData = [
  {
    img: Image1,
    title: 'Image',
    cols: 8,
  },
  {
    img: Image4,
    title: 'Image',

    cols: 4,
  },
  {
    img: Image5,
    title: 'Image',

    cols: 6,
  },
  {
    img: Image9,
    title: 'Image',

    cols: 6,
  },
  {
    img: Image3,
    title: 'Image',

    cols: 7,
  },
  {
    img: Image13,
    title: 'Image',

    cols: 5,
  },
  {
    img: Image11,
    title: 'Image',

    cols: 3,
  },
  {
    img: Image2,
    title: 'Image',

    cols: 9,
  },

  {
    img: Image6,
    title: 'Image',

    cols: 6,
  },
  {
    img: Image7,
    title: 'Image',

    cols: 6,
  },

  {
    img: Image10,
    title: 'Image',

    cols: 6,
  },

  {
    img: Image12,
    title: 'Image',

    cols: 6,
  },
];

const GalleryComponent = () => {
  const classes = useStyles();
  return (
    <main>
      <section id='contact' className='contact section-padding'>
        <div className='container'>
          <div className='heading text-center'>
            <div
              className='animated'
              data-animation='fadeInUpShorter'
              data-animation-delay='0.3s'
            >
              <h6 className='sub-title'>Ancient Community</h6>
              <h2 className='title'>Gallery</h2>
            </div>
          </div>

          <div className='row rounded'>
            <div className='col-xl-12 col-md-12 mx-auto'>
              <div className={classes.root}>
                <ImageList
                  rowHeight={320}
                  className={classes.imageList}
                  cols={12}
                >
                  {itemData.map((item) => (
                    <ImageListItem
                      key={item.img}
                      cols={item.cols || 1}
                      className={classes.imageListItem}
                    >
                      <img src={item.img} alt={item.title} />
                    </ImageListItem>
                  ))}
                </ImageList>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default GalleryComponent;
