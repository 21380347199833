import React, { useState, useEffect } from 'react';
import {useUpdateLogin} from '../../state/application/hooks'
import { updateLogin } from "../../state/application/actions";
import { Redirect } from 'react-router';
import AllRefersComponent from './AllRefersComponent';
import EditProfile from './EditProfile';
import PasswordUpdateComponents from './PasswordUpdateComponents';
import ShowProfileInfo from './ShowProfileInfo';

const ProfileComponent: React.FC = () => {
  const [isEditAble, setIsEditAble] = useState<boolean>(false);
  const [isChangePassword, setIsChangePassword] = useState<boolean>(false);
  let credentialObj = JSON.parse(localStorage.getItem("login"))
  const updateLoginStatus = useUpdateLogin()

  /*useEffect(() => {
    console.log('receieved updated login', updateLoginStatus)
    if (updateLoginStatus && updateLoginStatus !== "") {
      setLoginStatus(updateLoginStatus)
    }
  }, [updateLoginStatus])*/

  return (
    <div>
      {credentialObj ? <main>
        <section id='contact' className='contact section-padding'>
          <div className='container'>
            <div className='heading text-center'>
              <div
                className='animated'
                data-animation='fadeInUpShorter'
                data-animation-delay='0.3s'
              >
                <h6 className='sub-title'>Ancient Community</h6>
                <h2 className='title'>My Profile</h2>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-7'>
                <div className='bg-white p-5  rounded'>
                  {isEditAble && <EditProfile setIsEditAble={setIsEditAble} />}
                  {!isEditAble && !isChangePassword && (
                    <ShowProfileInfo
                      setIsEditAble={setIsEditAble}
                      setIsChangePassword={setIsChangePassword}
                    />
                  )}
                  {isChangePassword && (
                    <PasswordUpdateComponents
                      setIsChangePassword={setIsChangePassword}
                    />
                  )}
                </div>
              </div>
              <div className='col-md-5'>
                <AllRefersComponent />
              </div>
            </div>
          </div>
        </section>
      </main> :
      <div>
        <Redirect to="/"></Redirect>
      </div>}
    </div>
  );
};

export default ProfileComponent;
