import { makeStyles } from '@material-ui/core/styles';
import React, {useState} from 'react';
import { useDispatch } from "react-redux";
import { Redirect } from 'react-router';
import axios from 'axios';
import { notification, Spin} from 'antd';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import {ServerConfig} from '../../ancient-finance/config'
import { updateLogin } from "../../state/application/actions";

const useStyles = makeStyles((theme) => ({
  form: {
    boxShadow: '1px 1px 1px 10px #0001',
    background: '#fff',
    borderRadius: '5px',
    padding: '50px 30px',
  },
  invalidInput: {
    color: "red"
  }
}));


const SERVER_URL = ServerConfig.url;

const PhoneVerifyComponent = () => {
  const classes = useStyles();
  const [phoneNumber, setPhoneNumber] = useState('')
  const [verifyCode, setVerifyCode] = useState('')
  const [validPhoneNumber, setValidPhoneNumber] = useState(true)
  const [validVerifyCode, setValidVerifyCode] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [sentPhoneNumber, setSentPhoneNumber] = useState(false)
  const [sentVerifyCode, setSentVerifyCode] = useState(false)

  let credentialObj = JSON.parse(localStorage.getItem("login"))

  const sendPhoneNumber = (e) => {
    e.preventDefault();
    
    if (!phoneNumber || !validPhoneNumber) {
      setPhoneNumber("*Please fill out this field")
      setValidPhoneNumber(false);
      return;
    }

    if (!isPossiblePhoneNumber(phoneNumber)) {
      setPhoneNumber("*Invalid Phone Number")
      setValidPhoneNumber(false);
      return;
    }

    setSubmitting(true);

    axios.post(SERVER_URL + "regPhoneNumber", {pwd: credentialObj.pwd, userName: credentialObj.userName, phoneNumber})
    .then((res) => {
      if (res.data) {
        if (res.data.status) {
          setSentPhoneNumber(true)
        } else if (!res.data.status) {
          notification.open({
            message: 'Notification',
            description: res.data.message,
          });
        }
      }
      setSubmitting(false);
    }).catch((err) => {
      notification.open({
        message: 'Notification',
        description: "Unknow Error",
      });
      setSubmitting(false);
    });

    return;
  }

  const sendVerifyCode = (e) => {
    e.preventDefault();
    if (!verifyCode || !validVerifyCode) {
      setVerifyCode("*Please fill out this field")
      setValidVerifyCode(false);
      return;
    }

    setSubmitting(true)

    axios.post(SERVER_URL + "verifyPhone", {pwd: credentialObj.pwd, userName: credentialObj.userName, verifyCode})
    .then((res) => {
      if (res.data) {
        if (res.data.status) {
          setSentVerifyCode(true)
          credentialObj.phoneVerify = true;
          localStorage.setItem("login", JSON.stringify(credentialObj))
          window.location.href = window.location.origin
        } else if (!res.data.status) {
          notification.open({
            message: 'Notification',
            description: res.data.message,
          });
        }
      }
      setSubmitting(false)
    }).catch((err) => {
      notification.open({
        message: 'Notification',
        description: "Unknow Error",
      });
      setSubmitting(false)
    });

    return;
  }


  const onFocusPhoneNumber = () => {
    if (!validPhoneNumber) {
      setValidPhoneNumber(true)
      setPhoneNumber("")
    }
  }

  const onFocusVerifyCode = () => {
    if (!validVerifyCode) {
      setValidVerifyCode(true)
      setVerifyCode("")
    }
  }

  return (
    <main>
      {!sentPhoneNumber ? <section id='contact' className='contact section-padding'>
        <div className='container'>
          <div className='heading text-center'>
            <div
              className='animated'
              data-animation='fadeInUpShorter'
              data-animation-delay='0.3s'
            >
            </div>
          </div>

          <div className='row'>
            <div className='col-xl-6 col-md-12 mx-auto'>
              <form
                action='#'
                method='get'
                acceptCharset='utf-8'
                className={`text-center ${classes.form}`}
              >
                <h3
                  style={{ color: "#495057" }}
                >
                  Your Phone Number
                </h3>
                <br />
                <input
                  type='text'
                  className={`form-control mb-4 animated ${!validPhoneNumber && classes.invalidInput}`}
                  data-animation='fadeInUpShorter'
                  data-animation-delay='0.3s'
                  name='phoneNumber'
                  placeholder='Phone Number'
                  onFocus={onFocusPhoneNumber}
                  value={phoneNumber}
                  onChange={(e) => {setPhoneNumber(e.target.value)}}
                  maxLength={40}
                />

                {submitting ? <Spin /> : <button
                  type='submit'
                  className='btn btn-lg btn-gradient-purple animated'
                  data-animation='fadeInUpShorter'
                  data-animation-delay='0.3s'
                  style={{ boxShadow: '0 0 6px 0px #9e48cd' }}
                  onClick={sendPhoneNumber}
                >
                  Send
                </button>}
              </form>
            </div>
          </div>
        </div>
      </section> :
      <section id='contact' className='contact section-padding'>
      <div className='container'>
        <div className='heading text-center'>
          <div
            className='animated'
            data-animation='fadeInUpShorter'
            data-animation-delay='0.3s'
          >
          </div>
        </div>

        <div className='row'>
          <div className='col-xl-6 col-md-12 mx-auto'>
            <form
              action='#'
              method='get'
              acceptCharset='utf-8'
              className={`text-center ${classes.form}`}
            >
              <h3
                style={{ color: "#495057" }}
              >
                Submit Verify Code
              </h3>
              <br />
              <input
                type='text'
                className={`form-control mb-4 animated ${!validPhoneNumber && classes.invalidInput}`}
                data-animation='fadeInUpShorter'
                data-animation-delay='0.3s'
                name='verifyCode'
                placeholder='Verification Code'
                onFocus={onFocusVerifyCode}
                value={verifyCode}
                onChange={(e) => {setVerifyCode(e.target.value)}}
                maxLength={40}
              />

              {submitting ? <Spin /> :<button
                type='submit'
                className='btn btn-lg btn-gradient-purple animated'
                data-animation='fadeInUpShorter'
                data-animation-delay='0.3s'
                style={{ boxShadow: '0 0 6px 0px #9e48cd' }}
                onClick={sendVerifyCode}
              >
                Submit
              </button>}
            </form>
          </div>
        </div>
      </div>
    </section>
      }
    </main>
  );
};

export default PhoneVerifyComponent;
