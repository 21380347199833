import React from 'react';
import PhoneVerifyContainer from '../../containers/PhoneVerifyContainer';
import history from '../../utils/history';

const PhoneVerifyPage = () => {
  const {
    location: { pathname },
  } = history;

  return <PhoneVerifyContainer path={pathname} />;
};

export default PhoneVerifyPage;  
