import { makeStyles } from '@material-ui/core/styles';
import React, {useState} from 'react';
import { useDispatch } from "react-redux";
import { Redirect } from 'react-router';
import axios from 'axios';
import { notification } from 'antd';
import isEmail from 'validator/lib/isEmail';
import {ServerConfig} from '../../ancient-finance/config'
import { updateLogin } from "../../state/application/actions";

const useStyles = makeStyles((theme) => ({
  form: {
    boxShadow: '1px 1px 1px 10px #0001',
    background: '#fff',
    borderRadius: '5px',
    padding: '50px 30px',
  },
  invalidInput: {
    color: "red"
  }
}));


const SERVER_URL = ServerConfig.url;

const ForgotPwdComponent = () => {
  const classes = useStyles();
  const [mail, setMail] = useState('')
  const [validEmail, setValidEmail] = useState(true)
  const [sentEmail, setSentEmail] = useState(false)
  let credentialObj = JSON.parse(localStorage.getItem("login"))

  const resendEmail = (e) => {
    e.preventDefault();
    
    if (!mail || !validEmail) {
      setMail("*Please fill out this field")
      setValidEmail(false);
      return;
    }

    if (!isEmail(mail)) {
      setMail("*Invalid Email Address")
      setValidEmail(false);
      return;
    }

    axios.get(SERVER_URL + "forgotPwd/" + mail)
    .then((res) => {
      if (res.data) {
        if (res.data.status) {
          notification.open({
            message: 'Notification',
            description: "Resent Email Successfully",
          });
          setSentEmail(true)
        } else if (!res.data.status) {
          notification.open({
            message: 'Notification',
            description: res.data.message,
          });
        }
      }
    }).catch((err) => {
      notification.open({
        message: 'Notification',
        description: "Unknow Error",
      });
    });

    return;
  }

  const onFocusEmail = () => {
    if (!validEmail) {
      setValidEmail(true)
      setMail("")
    }
  }

  return (
    <main>
      {(!credentialObj && !sentEmail) ? <section id='contact' className='contact section-padding'>
        <div className='container'>
          <div className='heading text-center'>
            <div
              className='animated'
              data-animation='fadeInUpShorter'
              data-animation-delay='0.3s'
            >
            </div>
          </div>

          <div className='row'>
            <div className='col-xl-6 col-md-12 mx-auto'>
              <form
                action='#'
                method='get'
                acceptCharset='utf-8'
                className={`text-center ${classes.form}`}
              >
                <h3
                  style={{ color: "#495057" }}
                >
                  Send Verify Email
                </h3>
                <br />
                <div style={{textAlign: "center", marginBottom: 25 }}>
                  Did you forgot your password?
                  <br/>
                  We'll email you instructions to reset the password.
                </div>
                <input
                  type='text'
                  className={`form-control mb-4 animated ${!validEmail && classes.invalidInput}`}
                  data-animation='fadeInUpShorter'
                  data-animation-delay='0.3s'
                  name='userName'
                  placeholder='Email Address'
                  onFocus={onFocusEmail}
                  value={mail}
                  onChange={(e) => {setMail(e.target.value)}}
                  maxLength={40}
                />

                <button
                  type='submit'
                  className='btn btn-lg btn-gradient-purple animated'
                  data-animation='fadeInUpShorter'
                  data-animation-delay='0.3s'
                  style={{ boxShadow: '0 0 6px 0px #9e48cd' }}
                  onClick={resendEmail}
                >
                  Send
                </button>
              </form>
            </div>
          </div>
        </div>
      </section> :
      <section id='registersuccess' className='contact section-padding'>
      <div className='container'>
        <div className='heading text-center'>
          <div
            className='animated'
            data-animation='fadeInUpShorter'
            data-animation-delay='0.3s'
          >
            <h6 className='sub-title'>Ancient Community</h6>
            <h2 className='title'>Now check your email</h2>
          </div>
        </div>

        <div className='row'>
          <div className='col-xl-6 col-md-12 mx-auto'>
            <form
              action='#'
              method='get'
              acceptCharset='utf-8'
              className={`text-center ${classes.form}`}
            >
              <img src="theme-assets/images/verify.png" style={{width: "80%"}}/>
              <div style={{marginTop: "1.5em"}}>
                Check your inbox at {`<${mail}>`} and click the recovery link inside to recover your password
                <br/>This link will expire shortly, so verify soon!
              </div>
              <div style = {{marginTop: "1em"}}>
                <br/><strong>Don't see an email?</strong> Check your spam folder.
              </div>
            </form>
          </div>
        </div>
      </div>
     </section>}
    </main>
  );
};

export default ForgotPwdComponent;
