import { Configuration } from "./ancient-finance/config";

const configurations: { [env: string]: Configuration } = {
  development: {
    chainId: 3,
    networkName: "Ropsten Testnet",
    etherscanUrl: "https://ropsten.etherscan.io/",
    defaultProvider:
      "https://ropsten.infura.io/v3/d1713ebd37aa4a2492ccd72fe0f3d056",
    deployments: require("./ancient-finance/deployments/deployments.mainnet.json"),
    externalTokens: {},
    refreshInterval: 10000,
  },
  production: {
    chainId: 3,
    networkName: "Ropsten Testnet",
    etherscanUrl: "https://ropsten.etherscan.io/",
    defaultProvider:
      "https://ropsten.infura.io/v3/d1713ebd37aa4a2492ccd72fe0f3d056",
    deployments: require("./ancient-finance/deployments/deployments.mainnet.json"),
    externalTokens: {},
    refreshInterval: 10000,
  },
};

export default configurations[process.env.NODE_ENV || "development"];
