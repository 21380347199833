import { useEthers } from '@usedapp/core';
import React, { useEffect, useState /*, useContext*/ } from 'react';
import { useHistory } from 'react-router-dom'
import ReactGA from 'react-ga';
import { Redirect } from 'react-router';
//import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { HashLink } from 'react-router-hash-link';
import ConnectWallet from '../ConnectWallet'
import axios from 'axios';
import WalletProviderModal from '../WalletProviderModal';
import {useUpdateLogin} from '../../state/application/hooks'
import { updateLogin, updateRegisterWallet} from "../../state/application/actions";
import {ServerConfig} from '../../ancient-finance/config'
import './style.css';

ReactGA.initialize('G-2WEYB04PD2'); // add your tracking id here.
ReactGA.pageview(window.location.pathname + window.location.search);

const SERVER_URL = ServerConfig.url;


export default () => {
  const { account } = useEthers();
  const history = useHistory();
  const dispatch = useDispatch();
  const updateLoginStatus = useUpdateLogin()
  const loginKey = localStorage.getItem('login')
  const obj = JSON.parse(loginKey)
  const [loginStatus, setLoginStatus] = useState(obj ? obj.userName : '')
  const [logouted, setLogouted] = useState(false)
  const hasNoWallet = localStorage.getItem("pwdLogin") && (!obj.publicAddress)

  useEffect(() => {
    console.log('receieved updated login', updateLoginStatus)
    if (loginStatus !== updateLoginStatus) {
      console.log('----------receieved updated login', updateLoginStatus)
      if (obj && !obj.phoneVerify) {
        history.push("/phoneVerify")
      } else {
        history.push("/")
      }
      
    }
      
    if (updateLoginStatus) {
      setLoginStatus(updateLoginStatus)
    } else if (!localStorage.getItem('login')) {
      setLoginStatus('')
    }
  }, [updateLoginStatus])
  

  const [isWalletProviderOpen, setWalletProviderOpen] = useState(false);

  const handleWalletProviderOpen = () => {
    //setWalletProviderOpen(true);
  };

  const handleWalletProviderClose = () => {
    setWalletProviderOpen(false);
  };

  const logout = () => {
    const loginKey = localStorage.getItem('login');
    
    let apiKey = "";
    if (loginKey && JSON.parse(loginKey)) {
      apiKey = JSON.parse(loginKey).randString
    }
    localStorage.removeItem('login')
    setLoginStatus('')
    axios.post(SERVER_URL + "signout", {randString: apiKey})
    
    dispatch(
      updateLogin({
        userName: ""
      })
    )
    setLogouted(true)
    window.location.href = window.location.origin
  }

  // const installMetaMask = () => {
  //   window.open('https://metamask.io/download', '_blank');
  // };

  // useEffect(() => {
  //   if (active) {
  //     setIsLoading(false);
  //     setIsModalVisible(false);
  //   } else {
  //     setIsLoading(false);
  //   }
  // }, [connector, active, error]);

  return (
    <>
      <header className='page-header'>
        {/* <!-- Horizontal Menu Start--> */}
        <nav className='main-menu static-top navbar-dark navbar navbar-expand-lg fixed-top mb-1'>
          <div className='container'>
            <HashLink
              className='navbar-brand animated'
              data-animation='fadeInDown'
              data-animation-delay='1s'
              to={'/'}
            >
              <img
                src='./theme-assets/images/header-logo-csd.png'
                style={{ maxHeight: '40px' }}
                alt='Ancient Logo'
              />
              <span className='brand-text' style={{ fontSize: '16px' }}>
                <span className='font-weight-bold'>Ancient </span> Society
              </span>
            </HashLink>
            <button
              className='navbar-toggler'
              type='button'
              data-toggle='collapse'
              data-target='#navbarCollapse'
              aria-controls='navbarCollapse'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='navbar-toggler-icon'></span>
            </button>
            <div className='collapse navbar-collapse' id='navbarCollapse'>
              <div
                id='navigation'
                className='navbar-nav ml-auto'
                style={{ paddingTop: '3px' }}
              >
                <ul className='navbar-nav mt-1'>
                  {loginStatus && <li
                    className='nav-item animated'
                    data-animation='fadeInDown'
                    data-animation-delay='1.1s'
                  >
                    <HashLink
                      className='nav-link'
                      to='/#home'
                      role='button'
                      id='more'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      Home
                    </HashLink>
                  </li>}
                  <li
                    className='nav-item animated'
                    data-animation='fadeInDown'
                    data-animation-delay='1.1s'
                  >
                    <HashLink
                      className='nav-link blinking'
                      to='/#LioePublicSale'
                      role='button'
                      id='more'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      Public Sale<sup style={{color: "rgb(255 86 86)"}}>New</sup>
                    </HashLink>
                  </li>
                  {/*!loginStatus && <><li
                    className='nav-item animated'
                    data-animation='fadeInDown'
                    data-animation-delay='1.1s'
                  >
                    <HashLink
                      className='nav-link'
                      to='/register'
                      role='button'
                      id='more'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      Register
                    </HashLink>
                  </li>
                  <li
                    className='nav-item animated'
                    data-animation='fadeInDown'
                    data-animation-delay='1.1s'
                  >
                    <HashLink
                      className='nav-link'
                      to='/login'
                      role='button'
                      id='more'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      Login
                    </HashLink>
                  </li>
                  </>*/}
                  {loginStatus && <><li
                    className='nav-item animated'
                    data-animation='fadeInDown'
                    data-animation-delay='1.1s'
                  >
                    <HashLink
                      className='nav-link'
                      to='/leadership'
                      role='button'
                      id='more'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      Societies leadership Board
                    </HashLink>
                  </li>
                  <li
                    className='nav-item animated center'
                    data-animation='fadeInDown'
                    data-animation-delay='1.1s'
                  >
                    <HashLink
                      className='nav-link'
                      to='/#home'
                      role='button'
                      id='more'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      Community Exchange
                    </HashLink>
                  </li>
                  <li
                    className='nav-item animated'
                    data-animation='fadeInDown'
                    data-animation-delay='1.1s'
                  >
                    <HashLink
                      className='nav-link'
                      role='button'
                      id='more'
                      to='/'
                      aria-haspopup='true'
                      aria-expanded='false'
                      onClick={() => {window.open("https://ancientsmall.com/", "_blank")}}
                    >
                      Ancient Mall
                    </HashLink>
                  </li>
                  <li
                    className='nav-item animated'
                    data-animation='fadeInDown'
                    data-animation-delay='1.1s'
                  >
                    <HashLink
                      className='nav-link'
                      to='/#home'
                      role='button'
                      id='more'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      Community Projects
                    </HashLink>
                  </li>
                  {hasNoWallet && <li
                    className='nav-item animated'
                    data-animation='fadeInDown'
                    data-animation-delay='1.1s'
                    onClick={() => {dispatch(updateRegisterWallet({registerWallet: true}))}}
                  >
                    <HashLink
                      className='nav-link'
                      to='/#home'
                      role='button'
                      id='more'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      Register Wallet
                    </HashLink>
                  </li>}
                  </>}
                  {loginStatus && <li
                    className="dropdown show mr-2 px-2 animated nav-item"
                    data-animation="fadeInDown"
                    data-animation-delay="1.6s"
                  >
                    <HashLink
                      className="dropdown-toggle white"
                      to="/#"
                      role="button"
                      id="more"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {loginStatus}
                    </HashLink>
                    <div className="dropdown-menu" aria-labelledby="more">
                      <HashLink className="dropdown-item" to="/profile">
                        View Profile
                      </HashLink>
                      <HashLink className="dropdown-item" to="/bonus">
                        Referral Bonus
                      </HashLink>
                      <HashLink className="dropdown-item" to="/account">
                        Account
                      </HashLink>
                      <HashLink className="dropdown-item" to="/private#information">
                        Information
                      </HashLink>
                      <HashLink className="dropdown-item" to="/private#totalcountries">
                        Countries and Memberships
                      </HashLink>
                      <HashLink className="dropdown-item" to="/private#media">
                        Media
                      </HashLink>
                      <HashLink className="dropdown-item" to="/private#meeting">
                        Today Meeting
                      </HashLink>
                      <HashLink className="dropdown-item" to="/gallery">
                        Gallery
                      </HashLink>
                      {/*<HashLink className="dropdown-item" to="#" onClick={logout}>
                        Log out
                      </HashLink>*/}
                      {/*<HashLink className="dropdown-item" to="#">
                        News (soon)
                      </HashLink>
                      <HashLink className="dropdown-item" to="/team">
                        Team
                      </HashLink>
                      <HashLink className="dropdown-item" to="#">
                        Community Voice (soon)
                      </HashLink>
                      <HashLink className="dropdown-item" to="/faq">
                        FAQ
                      </HashLink>
                      <HashLink className="dropdown-item" to="/contact">
                        Contact
                      </HashLink>*/}
                    </div>
                  </li>}
                  {/*<li
                    className="nav-item animated"
                    data-animation="fadeInDown"
                    data-animation-delay="1.1s"
                  >
                    <a className="nav-link" href="/#mission">
                      What is our mission
                    </a>
                  </li>
                  <li
                    className="nav-item animated"
                    data-animation="fadeInDown"
                    data-animation-delay="1.2s"
                  >
                    <a className="nav-link" href="/#vision">
                      Solutions
                    </a>
                  </li>
                  <li
                    className="nav-item animated"
                    data-animation="fadeInDown"
                    data-animation-delay="1.3s"
                  >
                    <a className="nav-link" href="/#whitepaper">
                      Whitepaper
                    </a>
                  </li>
                  <li
                    className="nav-item animated"
                    data-animation="fadeInDown"
                    data-animation-delay="1.4s"
                  >
                    <a className="nav-link" href="/#token-distribution">
                      Token Sale
                    </a>
                  </li>
                  <li
                    className="nav-item animated"
                    data-animation="fadeInDown"
                    data-animation-delay="1.5s"
                  >
                    <a className="nav-link" href="/#roadmap">
                      Roadmap
                    </a>
                  </li>
                  <li
                    className="dropdown show mr-2 px-2 animated"
                    data-animation="fadeInDown"
                    data-animation-delay="1.6s"
                  >
                    <HashLink
                      className="dropdown-toggle white"
                      to="/#"
                      role="button"
                      id="more"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      More
                    </HashLink>
                    <div className="dropdown-menu" aria-labelledby="more">
                      <HashLink className="dropdown-item" to="#">
                        News (soon)
                      </HashLink>
                      <HashLink className="dropdown-item" to="/team">
                        Team
                      </HashLink>
                      <HashLink className="dropdown-item" to="#">
                        Community Voice (soon)
                      </HashLink>
                      <HashLink className="dropdown-item" to="/faq">
                        FAQ
                      </HashLink>
                      <HashLink className="dropdown-item" to="/contact">
                        Contact
                      </HashLink>
                    </div>
                  </li>*/}
                </ul>
                <span id='slide-line'></span>
                {/*<form className='form-inline mt-2 mt-md-0'>
                  {account ? (
                    <HashLink
                      className='btn btn-sm btn-gradient-purple btn-glow my-2 my-sm-0 animated'
                      data-animation='fadeInDown'
                      to='/dashboard'
                    >
                      Dashboard
                    </HashLink>
                  ) : (
                    <a
                      className='btn btn-sm btn-gradient-purple btn-glow my-2 my-sm-0 animated'
                      data-animation='fadeInDown'
                      data-animation-delay='1.8s'
                      onClick={handleWalletProviderOpen}
                      target='_self'
                      href
                    >
                      Connect Wallet
                    </a>
                  )}
                </form>*/}
                <ConnectWallet></ConnectWallet>
              </div>
            </div>
          </div>
        </nav>
        {/* <!-- /Horizontal Menu End--> */}
      </header>
      <WalletProviderModal
        open={isWalletProviderOpen}
        handleClose={handleWalletProviderClose}
      />
    </>
  );
};
