import EditIcon from '@material-ui/icons/Edit';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import React, {useEffect, useState } from 'react';
import { Input } from 'antd';
import Avatar from 'react-avatar';
import axios from 'axios';
import {ServerConfig} from '../../ancient-finance/config'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { MdContentCopy } from "react-icons/md";

import './ShowProfileInfo.css';

const SERVER_URL = ServerConfig.url;
const { TextArea } = Input;

const ShowProfileInfo: React.FC<{
  setIsEditAble: Function;
  setIsChangePassword: Function;
}> = ({ setIsEditAble, setIsChangePassword }) => {
  const [aboutMe, setAboutMe] = useState("")
  const [membership, setMembership] = useState([])
  let credentialObj = JSON.parse(localStorage.getItem("login"))
  let apiKey = localStorage.getItem('apiKey')

  useEffect(() => {
    axios.post(SERVER_URL + "aboutme", {pwd: credentialObj.pwd, userName: credentialObj.userName})
    .then(res => {
      if (res.data.status) {
        setAboutMe(res.data.message.aboutMe)
      }
    }).catch(err => {
      console.log(err)
    })

    axios.get(SERVER_URL + "membership")
    .then(res => setMembership(res.data.message))
  }, [])

  return (
    <div className='ShowProfileInfo '>
      <div className='ShowProfileInfo__container'>
        <div className='row no-gutters'>
          <div className='col-md-8' style={{marginBottom: 20}}>
            <div>
              {/* <div className='ShowProfileInfo__nameSection'> */}
              <div>
                <span className='position-relative h2'>
                  {credentialObj.fullName}
                </span>
                <span className='badge badge-primary badge-pill ShowProfileInfo__badge' style={{display: "inline-block"}}>
                  {membership[credentialObj.membership]}
                </span>
                {/* <div className='badge badge-primary badge-pill ShowProfileInfo__badge' style={{display: "inline-block"}}>
                  11111111111{membership[credentialObj.membership]}
                </div> */}
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            {/*<button
              className='btn btn-primary px-2 py-1 ml-2 btn-sm float-right'
              onClick={() => {
                setIsEditAble(false);
                setIsChangePassword(true);
              }}
            >
              <VpnKeyIcon />
            </button>*/}
            <button
              className='btn btn-primary btn-sm px-2 py-1 float-right'
              onClick={() => {
                setIsEditAble(true);
                setIsChangePassword(false);
              }}
            >
              <EditIcon />
            </button>
          </div>
        </div>
        <div style={{marginBottom: 15}}>
          <Avatar name={credentialObj.userName} size="100" round={true}/>
          {/* <Button type="primary" style={{marginLeft: 15}}>Change</Button> */}
        </div>
        {/* <p className='text-muted text-primary' style={{marginTop: 10}}>{credentialObj.userName}</p> */}
        <div style={{marginTop: 50}}>
          <div style={{marginBottom: 10}}>
            Biography
          </div>
          <TextArea placeholder="Please Edit your biography here" maxLength={1000} rows={6} value={aboutMe} readOnly/>
        </div>
      </div>
    </div>
  );
};

export default ShowProfileInfo;
