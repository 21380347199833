import React from 'react';
import ProfileComponent from '../../components/ProfileComponent';

const ProfileContainer = (props) => {
  return (
    <div>
      <ProfileComponent {...props} />
    </div>
  );
};

export default ProfileContainer;
