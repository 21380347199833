import React, { createContext, useEffect, useState } from "react";
import { useEthers } from "@usedapp/core";
import AncientFinance from "../../ancient-finance";
import config from "../../config";

export interface AncientFinanceContext {
  ancientFinance?: AncientFinance;
}

export const Context = createContext<AncientFinanceContext>({
  ancientFinance: null,
});

export const AncientFinanceProvider: React.FC = ({ children }) => {
  const { account, chainId, library, active, activate, deactivate, error } =
    useEthers();
  const [ancientFinance, setAncientFinance] =
    useState<AncientFinance>();

  useEffect(() => {
    if (!ancientFinance) {
      const ancientFinance = new AncientFinance(config);
      if (account) {
        // wallet was unlocked at initialization
        ancientFinance.unlockWallet(library, account);
      }
      setAncientFinance(ancientFinance);
    } else if (account) {
      ancientFinance.unlockWallet(library, account);
    }
  }, [account, library, ancientFinance]);

  return (
    <Context.Provider value={{ ancientFinance }}>
      {children}
    </Context.Provider>
  );
};
