import React from 'react';
import ProfileContainer from '../../containers/ProfileContainer';

const ProfilePage = (props) => {
  return (
    <div>
      <ProfileContainer {...props} />
    </div>
  );
};

export default ProfilePage;
