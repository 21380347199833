import React, {useState, useEffect} from 'react';
import { notification } from 'antd';
import axios from 'axios';
import {ServerConfig} from '../../ancient-finance/config'
import './EditProfile.css';

const biography = `<p className='text-dark'>
A person (plural people or persons) is a being that has certain
capacities or attributes such as reason, morality, consciousness or
self-consciousness, and being a part of a culturally established
form of social relations such as kinship, ownership of property, or
legal responsibility.The defining features of personhood and,
consequently, what makes a person count as a person, differ widely
among cultures and contexts.
</p>
<p className='text-dark'>
In addition to the question of personhood, of what makes a being
count as a person to begin with, there are further questions about
personal identity and self: both about what makes any particular
person that particular person instead of another, and about what
makes a person at one time the same person as they were or will be
at another time despite any intervening changes.
</p>
<p className='text-dark'>
The plural form "people", is often used to refer to an entire nation
or ethnic group (as in "a people"), and this was the original
meaning of the word; it subsequently acquired its use as a plural
form of person. The plural form "persons" is often used in
philosophical and legal writing.
</p>`;

const SERVER_URL = ServerConfig.url;

const EditProfile: React.FC<{ setIsEditAble: Function }> = ({
  setIsEditAble,
}) => {
  const [aboutMe, setAboutMe] = useState('')
  const [editing, setEditing] = useState(false)
  let credentialObj = JSON.parse(localStorage.getItem("login"))
  let apiKey = localStorage.getItem('apiKey')
  const [fullName, setFullName] = useState(credentialObj ? credentialObj.fullName : '')
  const [userName, setUserName] = useState(credentialObj ? credentialObj.userName : '')
  const [emailAddr, setEmailAddr] = useState(credentialObj ? credentialObj.emailAddr : '')

  useEffect(() => {
    if (editing) 
      return;
    
    axios.post(SERVER_URL + "aboutme", {pwd: credentialObj.pwd, userName: credentialObj.userName})
    .then(res => {
      if (res.data.status) {
        setAboutMe(res.data.message.aboutMe)
      }
      console.log(res.data.messag)
    }).catch(err => {
      console.log(err)
    })
  }, [aboutMe])

  const submitHandler = (event: any) => {
    event.preventDefault();
    setIsEditAble(false);
  };

  const submitAboutme = () => {
    axios.post(SERVER_URL + "aboutme", {pwd: credentialObj.pwd, userName: credentialObj.userName, aboutMe: aboutMe})
    .then(res => {
      if (res.data.status) {
        notification.open({
          message: 'Notification',
          description: "Updated Biography Successfully",
        });
        //setAboutMe(res.data.message)
      } else {
        notification.open({
          message: 'Notification',
          description: res.data.message,
        });
      }
    }).catch(err => {
      notification.open({
        message: 'Notification',
        description: "Netwokr error",
      });
    })
  }

  return (
    <div>
      <h2>Update your biography</h2>
      <div className='divider'></div>
      <form onSubmit={submitHandler}>
        <input
          type='text'
          className='form-control mb-4 animated text-dark bg-transparent'
          data-animation='fadeInUpShorter'
          data-animation-delay='0.3s'
          name='name'
          readOnly={true}
          placeholder='Your FullName'
          value={fullName}
          onChange={(e) => {setFullName(e.target.value)}}
        />
        <input
          type='text'
          className='form-control mb-4 animated text-dark bg-transparent'
          data-animation='fadeInUpShorter'
          data-animation-delay='0.3s'
          name='name'
          readOnly={true}
          placeholder='Your UserName'
          value={userName}
          onChange={(e) => {setUserName(e.target.value)}}
        />
        <input
          type='email'
          className='form-control mb-4 animated text-dark bg-transparent'
          data-animation='fadeInUpShorter'
          data-animation-delay='0.3s'
          name='name'
          readOnly={true}
          placeholder='Name'
          value={emailAddr}
          onChange={(e) => {setEmailAddr(e.target.value)}}
        />

        <textarea
          className='form-control mb-4 animated text-dark border EditProfileTextArea'
          data-animation='fadeInUpShorter'
          data-animation-delay='0.3s'
          name='biography'
          placeholder='Please fill out your biography'
          id=''
          cols={30}
          rows={10}
          maxLength={1000}
          onChange={(e) => {setAboutMe(e.target.value)}}
          onFocus={() => {setEditing(true)}}
          value={aboutMe}
        />

        <div className='text-right'>
          <button
            type='button'
            className='btn btn-danger btn-sm mr-2'
            onClick={() => setIsEditAble(false)}
          >
            Cancel
          </button>
          <button type='submit' className='btn btn-primary btn-sm' onClick={submitAboutme}>
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditProfile;
