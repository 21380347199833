import React from "react";
import history from "../../utils/history";

import RegisterContainer from "../../containers/RegisterContainer";

const RegisterPage = (props) => {
  const {
    location: { pathname },
  } = history;

  return <RegisterContainer path={pathname}  {...props} />;
};

export default RegisterPage;
