import React from "react";
import CryptoRegisterComponent from "../../components/CryptoRegisterComponent";

const RegisterContainer = (props) => {
  return (
    <>
      <CryptoRegisterComponent {...props} />
    </>
  );
};

export default RegisterContainer;
