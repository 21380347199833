import { Configuration, TokenInfo } from "./config";
import { CsdStat } from "./types";
// import { GraphQLClient, gql } from 'graphql-request';
import { BigNumber, Contract, ethers /*, EventFilter*/ } from "ethers";
// import Web3 from "web3";
import { getDefaultProvider } from "../utils/provider";
import { TransactionResponse } from "@ethersproject/providers";
/**
 * An API module of Ancient Finance contracts.
 * All contract-interacting domain logic should be defined in here.
 */
export class AncientFinance {
  myAccount: string;
  provider: ethers.providers.Web3Provider;
  signer?: ethers.Signer;
  config: Configuration;
  contracts: { [name: string]: Contract };
  externalTokens: { [name: string]: TokenInfo };

  constructor(cfg: Configuration) {
    const { deployments, externalTokens } = cfg;
    const provider = getDefaultProvider();

    // loads contracts from deployments
    this.contracts = {};
    for (const [name, deployment] of Object.entries(deployments)) {
      this.contracts[name] = new Contract(
        deployment.address,
        deployment.abi,
        provider
      );
    }
    this.externalTokens = {};
    for (const [symbol, [address, decimal]] of Object.entries(externalTokens)) {
      this.externalTokens[symbol] = { address, decimal };
    }
    this.config = cfg;
    this.provider = provider;
  }

  /**
   * @param provider From an unlocked wallet. (e.g. Metamask)
   * @param account An address of unlocked wallet account.
   */
  unlockWallet(provider: any, account: string) {
    const { CSDCrowdsale } = this.contracts;
    this.signer = provider.getSigner(0);
    this.myAccount = account;
    for (const [name, contract] of Object.entries(this.contracts)) {
      this.contracts[name] = contract.connect(this.signer);
    }
    console.log(`🔓 Wallet is unlocked. Welcome, ${account}!`);
  }

  get isUnlocked(): boolean {
    return !!this.myAccount;
  }

  async getRate(): Promise<number> {
    const { CSDCrowdsale } = this.contracts;
    try {
      const rate = await CSDCrowdsale.rate();
      return Number(rate);
    } catch (err) {
      console.log(err);
      return 0;
    }
  }

  async buyCSD(amount: number): Promise<TransactionResponse> {
    if (this.myAccount === undefined) return;
    const { CSDCrowdsale } = this.contracts;
    const rate = await this.getRate();
    const currentNonce = await this.signer.getTransactionCount();
    let overrides = {
      from: this.myAccount,
      value: ((amount * 1e18) / rate).toFixed(),
      nonce: currentNonce + 1,
    };
    return await CSDCrowdsale.buyTokens(this.myAccount, overrides);
  }

  async getCsdPrice(): Promise<string> {
    // const { EACAggregatorProxy } = this.contracts;
    try {
      // const roundData = await EACAggregatorProxy.latestRoundData();
      // const rate = await this.getRate();
      // const ethPrice = BigNumber.from(roundData[1]);
      // const price = Number(ethPrice) / (rate * 10 ** 8);
      // return Number(price).toFixed(9);
      return "0.00000345";
    } catch (err) {
      console.log(err);
      return "";
    }
  }

  async getCsdStat(): Promise<CsdStat> {
    const { CSDCrowdsale } = this.contracts;
    const sellAmount = await CSDCrowdsale.sellAmount();
    const maxAmountToBuyPerTransaction =
      await CSDCrowdsale.maxAmountToBuyPerTransaction();
    const maxAmountToSell = await CSDCrowdsale.maxAmountToSell();
    const totalAmount = await CSDCrowdsale.totalAmount();
    const sellPercent = ((100 * sellAmount) / totalAmount).toFixed(2);
    return {
      sellAmount: Number(sellAmount) / 1e18,
      maxAmountToBuyPerTransaction: Number(maxAmountToBuyPerTransaction) / 1e18,
      maxAmountToSell: Number(maxAmountToSell) / 1e18,
      totalAmount: Number(totalAmount) / 1e18,
      sellPercent: sellPercent,
    };
  }
}
