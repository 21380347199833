import React from "react";

export default () => {
  return (
    <footer
      className="footer static-bottom footer-dark footer-custom-class"
      data-midnight="white"
    >
      <div className="container">
        <div className="footer-wrapper">
          <div className="row">
            <div className="col-md-4">
              <div className="about">
                <div
                  className="title animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.2s"
                >
                  <img
                    src="theme-assets/images/logo.png"
                    style={{ maxHeight: "35px" }}
                    alt="CSD Logo"
                  />
                  <span className="logo-text" style={{ fontSize: "1rem" }}>
                    <span className="font-weight-bold">&nbsp;Ancient</span> Society
                  </span>
                </div>
                <div
                  className="about-text animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.3s"
                >
                  <p className="grey-accent2">
                    Next stage of DeFi’s evolution with minimized risk of being
                    scammed and one step closer to money revolution.
                  </p>
                </div>
                <ul className="social-buttons list-unstyled mb-5">
                  <li
                    className="animated"
                    data-animation="fadeInUpShorter"
                    data-animation-delay="0.2s"
                  >
                    <a
                      href="https://ancientsmedia.com"
                      title="AncientMedia"
                      className="btn font-medium"
                    >
                    <svg width="33" height="33" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlSpace="preserve">
                      <g id="Layer_1">
                        <text stroke="#000" strokeWidth="0" x="11.31573" y="13.86937" id="svg_1" fontSize="17" fontFamily="Azeret Mono" textAnchor="start" xmlSpace="preserve">A</text>
                        {/* <rect opacity="0.95" stroke="#000" fill="#ffffff" strokeWidth="0" x="0.66665" y="14.87343" width="31.57033" height="18" id="svg_8" rx="4"/> */}
                        <text transform="matrix(0.936914 0 0 1.45532 0.854545 -10.0503)" stroke="#000" xmlSpace="preserve" textAnchor="start" fontFamily="Noto Sans JP" fontSize="11" id="svg_2" y="26.9128" x="1.39071" strokeWidth="0">Media</text>
                      </g>
                    </svg>
                    </a>
                  </li>
                  <li
                    className="animated"
                    data-animation="fadeInUpShorter"
                    data-animation-delay="0.2s"
                  >
                    <a
                      href="https://farming.ancient.cash/"
                      title="Farming"
                      className="btn font-medium"
                    >
                      <img
                        src="theme-assets/images/tractor-white.png"
                        style={{ maxHeight: "35px" }}
                        alt="farming"
                      />
                    </a>
                  </li>
                  <li
                    className="animated"
                    data-animation="fadeInUpShorter"
                    data-animation-delay="0.2s"
                  >
                    <a
                      href="https://t.me/joinchat/7Eez239jl_thOTM0"
                      title="Telegram"
                      className="btn font-medium"
                    >
                      <i className="fa fa-telegram"></i>
                    </a>
                  </li>
                  <li
                    className="animated"
                    data-animation="fadeInUpShorter"
                    data-animation-delay="0.2s"
                  >
                    <a
                      href="https://youtube.com/channel/UCRp7acalw10Bq6dv7x8qjBg"
                      title="Youtube"
                      className="btn font-medium"
                    >
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                  <li
                    className="animated"
                    data-animation="fadeInUpShorter"
                    data-animation-delay="0.2s"
                  >
                    <a
                      href="https://twitter.com/SocietyAncient?s=08"
                      title="Youtube"
                      className="btn font-medium"
                    >
                      {" "}
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li
                    className="animated"
                    data-animation="fadeInUpShorter"
                    data-animation-delay="0.2s"
                  >
                    <a
                      href="https://web.facebook.com/groups/3119395951622152/"
                      title="Facebook"
                      className="btn font-medium"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="links">
                <h5
                  className="title animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.2s"
                >
                  Useful pages
                </h5>
                <ul className="useful-links">
                  <li
                    className="animated"
                    data-animation="fadeInUpShorter"
                    data-animation-delay="0.4s"
                  >
                    <a href="/#team">Our Team</a>
                  </li>
                  <li
                    className="animated"
                    data-animation="fadeInUpShorter"
                    data-animation-delay="0.4s"
                  >
                    {/*<a href="/news">Read our News</a>*/}
                    <a href="#">Read our News</a>
                  </li>
                  <li
                    className="animated"
                    data-animation="fadeInUpShorter"
                    data-animation-delay="0.4s"
                  >
                    {/*<a href="/faq">Frequently asked questions</a>*/}
                    <a href="#">Frequently asked questions</a>
                  </li>
                  <li
                    className="animated"
                    data-animation="fadeInUpShorter"
                    data-animation-delay="0.4s"
                  >
                    <a href="/#contact">Contact us</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-4">
              <div className="feed">
                <h5
                  className="title animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.4s"
                >
                  Our Domains
                </h5>
                <ul className="useful-links float-left mr-5">
                  <li
                    className="animated"
                    data-animation="fadeInUpShorter"
                    data-animation-delay="0.4s"
                  >
                    <a href="#">ancient.cash</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="copy-right mx-auto text-center">
            <span className="copyright">
              Copyright &copy; 2021, Ancient Society
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};
