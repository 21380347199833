import { makeStyles } from '@material-ui/core/styles';
import React, {useEffect, useState} from 'react';
import { notification, Spin, Modal } from 'antd';
import { useDispatch } from "react-redux";
import axios from 'axios';
import {ServerConfig} from '../../ancient-finance/config'
import isEmail from 'validator/lib/isEmail';
import { Redirect } from 'react-router';
import {useUpdateWallet, useUpdateRequireVerify} from '../../state/application/hooks'
import { updateLogin } from "../../state/application/actions";

const useStyles = makeStyles((theme) => ({
  form: {
    boxShadow: '1px 1px 1px 10px #0001',
    background: '#fff',
    borderRadius: '5px',
    padding: '50px 30px',
  },
  invalidInput: {
    color: "red"
  }
}));

const SERVER_URL = ServerConfig.url;

const CryptoRegisterComponent = (props) => {
  console.log(props)
  const classes = useStyles();
  const dispatch = useDispatch();
  const { match: { params: { publicAddress }} } = props;
  const { match: { params: { referral }} } = props;
  if (referral) {
    localStorage.setItem('referral', referral);
  }
  const [walletAddr, setWalletAddr] = useState(publicAddress ? publicAddress : "")
  const [referralUser, setReferralUser] = useState( referral ? referral : "");
  const [regBtnFrozen, setRegBtnFrozen] = useState(false)

  let credentialObj = JSON.parse(localStorage.getItem("login"))
  
  useEffect(() => {
    setWalletAddr(publicAddress ? publicAddress : "")
  }, [publicAddress])

  useEffect(() => {
    setReferralUser(referral || localStorage.getItem('referral') || '')
  }, [referral])
  
  //const [friend, setFriend] = useState(referral ? referral : "")

  const updateWalletStatus = useUpdateWallet()
  const updateRequireVerifyStatus = useUpdateRequireVerify()
  
  const [fullName, setFullName] = useState('');
  const [userName, setUserName] = useState('');
  const [emailAddr, setEmailAddr] = useState('');
  
  //const [referralUser, setReferralUser] = useState(localStorage.getItem('referral') || '');
  const [pwd1, setPwd1] = useState('');
  const [pwd2, setPwd2] = useState('');
  const [validFullName, setValidFullName] = useState(true)
  const [validUserName, setValidUserName] = useState(true)
  const [validEmailAddr, setValidEmailAddr] = useState(true)
  const [validReferralUser, setValidReferralUser] = useState(true)
  const [acceptTerm, setAcceptTerm] = useState(false)
  const [validPwd1, setValidPwd1] = useState(true)
  const [strongPwd1, setStrongPwd1] = useState(true)
  const [validPwd2, setValidPwd2] = useState(true)
  const [registerSuccess, setRegisterSuccess] = useState(false)
  const [registering, setRegistering] = useState(false)

  const isStrongPwd = (str) => {
    return (str.length < 8) ? false : true;
  }
  const register = (e) => {
    e.preventDefault();
    
    let allValid = true;

    // if (!walletAddr) {
    //   notification.open({
    //       message: 'Alert',
    //       description: "Please Connect Your Wallet",
    //       duration: 1.5
    //   });
    // }

    if (!fullName || !validFullName) {
      setFullName("*Please fill out this field")
      setValidFullName(false);
      return;
    }

    if (!userName || !validUserName || userName.trim().length <= 0) {
      setUserName("*Please fill out this field")
      setValidUserName(false)
      return;
    }

    if (!emailAddr || !validEmailAddr || emailAddr.trim().length <= 0) {
      if (emailAddr !== "*Invalid Email Address") {
        setEmailAddr("*Please fill out this field")
      }
      setValidEmailAddr(false)
      return;
    }

    if (!isEmail(emailAddr)) {
      setEmailAddr("*Invalid Email Address")
      setValidEmailAddr(false)
      return;
    }

    if (!validReferralUser) {
      setReferralUser("*Please fill out this field")
      setValidReferralUser(false)
      return;
    }

    if (!pwd1 || !validPwd1) {
      if (pwd1 !== "*Must be longer than 8 letters") {
        setPwd1("*Please fill out this field")
      }
      setValidPwd1(false)
      return;
    }

    if (!isStrongPwd(pwd1)) {
      setPwd1("*Must be longer than 8 letters")
      setValidPwd1(false)
      return;
    }

    if (!pwd2 || !validPwd2) {
      if (pwd2 !== "*Unmatched password") {
        setPwd2("*Please fill out this field")
      }
      setValidPwd2(false)
      return;
    }

    if (pwd1 !== pwd2) {
      setPwd2("*Unmatched password")
      setValidPwd2(false)
      return;
    }
    //console.log(SERVER_URL, updateWalletStatus)

    setRegistering(true)

    axios.post(SERVER_URL + "signup", {
      fullName,
      userName: userName.trim(),
      emailAddr: emailAddr.trim(),
      referralUser,
      publicAddress: walletAddr || "",
      pwd: pwd1
    }).then((res) => {
      if (res.data) {
        if (res.data.status) {
          setRegistering(false)
          setRegisterSuccess(true)
          /*localStorage.setItem('login', JSON.stringify({fullName, userName, emailAddr}))
          localStorage.setItem('walletLogin', 'connected')
          dispatch(
            updateLogin({
              userName
            })
          )*/
        } else if (!res.data.status) {
          setRegistering(false)
          if (res.data.message === "The Referral UserName doesn't exist") {
            setReferralUser("The Referral UserName doesn't exist")
            setValidReferralUser(false)
          } else if (res.data.message && res.data.message.userName === userName) {
            setUserName("*The User Name already exists")
            setValidUserName(false)
          } else if (res.data.message && res.data.message.emailAddr === emailAddr) {
            setEmailAddr("*The Email Address already exists")
            setValidEmailAddr(false)
          } else if (res.data.message && res.data.message.publicAddress === walletAddr) {
            notification.open({
                message: 'Alert',
                description: "The Wallet Address alread exists",
                duration: 1.5
            });
          }
        }
        setRegistering(false)
      }
    }).catch((err) => {
      setRegistering(false)
      console.log('error', err)
    });

    setRegBtnFrozen(true);
    setTimeout(() => {
      setRegBtnFrozen(false)
    }, 2000);
  }

  const onFocusFullName = () => {
    if (!validFullName) {
      setValidFullName(true)
      setFullName("")
    }
  }

  const onFocusUserName = () => {
    if (!validUserName) {
      setValidUserName(true)
      setUserName("")
    }
  }

  const onFocusEmailAddr = () => {
    if (!validEmailAddr) {
      setValidEmailAddr(true)
      setEmailAddr("")
    }
  }

  const onFocusReferralUser = () => {
    if (!validReferralUser) {
      setValidReferralUser(true)
      setReferralUser("")
    }
  }

  const onFocusPwd1 = () => {
    if (!validPwd1 || !strongPwd1) {
      setValidPwd1(true)
      setPwd1("")
    }
  }

  const onFocusPwd2 = () => {
    if (!validPwd2) {
      setValidPwd2(true)
      setPwd2("")
    }
  }

  return (
    <main>
      {credentialObj && <Redirect to="/"></Redirect>}
      {((!registerSuccess)) ? 
      <section id='register' className='contact section-padding'>
        <div className='container'>
          <div className='heading text-center'>
            <div
              className='animated'
              data-animation='fadeInUpShorter'
              data-animation-delay='0.3s'
            >
              <h6 className='sub-title'>Ancient Community</h6>
              <h2 className='title'>Register</h2>
            </div>
          </div>

          <div className='row'>
            <div className='col-xl-6 col-md-12 mx-auto'>
              <form
                action='#'
                method='get'
                acceptCharset='utf-8'
                className={`text-center ${classes.form}`}
              >
                <div style={{color: "rgb(127,127,145)", wordBreak: "break-all"}}>
                  {walletAddr && "Your wallet Address: "}
                  <br/>
                  {walletAddr}
                </div>
                <input
                  type='text'
                  className={`form-control mb-4 animated ${!validFullName && classes.invalidInput}`}
                  data-animation='fadeInUpShorter'
                  data-animation-delay='0.3s'
                  name='name'
                  placeholder='Your Name'
                  value={fullName}
                  maxLength={25}
                  onFocus={onFocusFullName}
                  onChange={(e) => {setFullName(e.target.value)}}
                />

                <input
                  type='text'
                  className={`form-control mb-4 animated ${!validUserName && classes.invalidInput}`}
                  data-animation='fadeInUpShorter'
                  data-animation-delay='0.3s'
                  name='userName'
                  placeholder='Username'
                  value={userName}
                  maxLength={25}
                  onFocus={onFocusUserName}
                  onChange={(e) => {setUserName(e.target.value)}}
                />

                <input
                  type='text'
                  className={`form-control mb-4 animated ${!validEmailAddr && classes.invalidInput}`}
                  data-animation='fadeInUpShorter'
                  data-animation-delay='0.3s'
                  name='mail'
                  placeholder='Email address'
                  value={emailAddr}
                  maxLength={40}
                  onFocus={onFocusEmailAddr}
                  onChange={(e) => {setEmailAddr(e.target.value)}}
                />
                <input
                  type='text'
                  className={`form-control mb-4 animated ${!validReferralUser && classes.invalidInput}`}
                  data-animation='fadeInUpShorter'
                  data-animation-delay='0.3s'
                  name='referralName'
                  placeholder='Your Referral Name'
                  value={referralUser}
                  maxLength={25}
                  onFocus={onFocusReferralUser}
                  onChange={(e) => {setReferralUser(e.target.value)}}
                />
                <input
                  type={(validPwd1 && strongPwd1) ? 'password' : 'edit'}
                  className={`form-control mb-4 animated ${(!validPwd1 || !strongPwd1) && classes.invalidInput}`}
                  data-animation='fadeInUpShorter'
                  data-animation-delay='0.3s'
                  name='password1'
                  placeholder='Your Password'
                  value={pwd1}
                  maxLength={25}
                  onFocus={onFocusPwd1}
                  onChange={(e) => {setPwd1(e.target.value)}}
                />

                <input
                  type={validPwd2 ? 'password' : 'edit'}
                  className={`form-control mb-4 animated ${!validPwd2 && classes.invalidInput}`}
                  data-animation='fadeInUpShorter'
                  data-animation-delay='0.3s'
                  name='password2'
                  placeholder='Confirm Password'
                  value={pwd2}
                  maxLength={25}
                  onFocus={onFocusPwd2}
                  onChange={(e) => {setPwd2(e.target.value)}}
                />
                <br/>
                <div style={{width: "100%", marginBottom: 8, color: "rgb(127,127,145)"}}>
                  <input
                    type='checkbox'
                    className='mb-4 animate'
                    data-animation='fadeInUpShorter'
                    data-animation-delay='0.3s'
                    onChange={() => setAcceptTerm(!acceptTerm)}
                    value={acceptTerm}
                  /> I agree to the Terms and Conditions
                </div>
                {registering ? <Spin /> : <button
                  type='submit'
                  onClick={register}
                  className='btn btn-lg btn-gradient-purple  animated'
                  data-animation='fadeInUpShorter'
                  data-animation-delay='0.3s'
                  style={{ boxShadow: '0 0 6px 0px #9e48cd' }}
                  disabled={!acceptTerm || regBtnFrozen}
                >
                  Register
                </button>}
                <br />
                {/*<div className='row align-items-center my-5'
                  <div className='col-md-6'>
                    <div class='custom-control custom-checkbox text-left'>
                      <a href='/forgot'>
                        Forgot Password
                      </a>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div class='custom-control custom-checkbox text-left'>
                      <a href='/login'>
                        Login you account
                      </a>
                    </div>
                  </div>
                </div>*/}
              </form>
            </div>
          </div>
        </div>
      </section>
       :
      <section id='registersuccess' className='contact section-padding'>
       <div className='container'>
         <div className='heading text-center'>
           <div
             className='animated'
             data-animation='fadeInUpShorter'
             data-animation-delay='0.3s'
           >
             <h6 className='sub-title'>Ancient Community</h6>
             <h2 className='title'>{updateRequireVerifyStatus ? "Please verify your email" : "Great, now verify your email"}</h2>
           </div>
         </div>

         <div className='row'>
           <div className='col-xl-6 col-md-12 mx-auto'>
             <form
               action='#'
               method='get'
               acceptCharset='utf-8'
               className={`text-center ${classes.form}`}
             >
               <img src="theme-assets/images/verify.png" style={{width: "80%"}}/>
               <div style={{marginTop: "1.5em"}}>
                 We sent the verification email. Check your inbox at {updateRequireVerifyStatus? updateRequireVerifyStatus : `<${emailAddr}>`} and click the verification link inside to complete your registration. 
                 <br/>This link will expire shortly, so verify soon!
               </div>
               <div style = {{marginTop: "1em"}}>
                 <br/><strong>Don't see an email?</strong> Check your spam folder.
                 {updateRequireVerifyStatus && <><br/><a href="https://ancient.cash/forgot">Resend Verification Email</a></>}
               </div>
             </form>
           </div>
         </div>
       </div>
      </section>}
    </main>
  );
};

export default CryptoRegisterComponent;
