import React from 'react';
import LoginContainer from '../../containers/LoginContainer';
import history from '../../utils/history';

const LoginPage = () => {
  const {
    location: { pathname },
  } = history;

  return <LoginContainer path={pathname} />;
};

export default LoginPage;  
