import React from 'react';
import ForgotPwdContainer from '../../containers/ForgotPwdContainer';
import history from '../../utils/history';

const ForgotPwdPage = () => {
  const {
    location: { pathname },
  } = history;

  return <ForgotPwdContainer path={pathname} />;
};

export default ForgotPwdPage;  
