import React from "react";

const CryptoVerticalSocialComponent = () => {
  return (
    <nav className="vertical-social">
      <ul>
        <li>
          <a href="https://ancientsmedia.com">
          <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlSpace="preserve">
            <g id="Layer_1">
              <text stroke="#000" strokeWidth="0" x="11.31573" y="13.86937" id="svg_1" fontSize="13" fontFamily="Noto Sans JP" textAnchor="start" xmlSpace="preserve">A</text>
              {/* <rect opacity="0.95" stroke="#000" fill="#ffffff" strokeWidth="0" x="0.66665" y="14.87343" width="31.57033" height="18" id="svg_8" rx="4"/> */}
              <text transform="matrix(0.936914 0 0 1.45532 0.854545 -10.0503)" stroke="#000" xmlSpace="preserve" textAnchor="start" fontFamily="Noto Sans JP" fontSize="10" id="svg_2" y="26.9128" x="1.39071" strokeWidth="0">Media</text>
            </g>
          </svg>
          </a>
        </li>
        <li>
          <a href="https://farming.ancient.cash">
            <img
              src="theme-assets/images/tractor.png"
              style={{ width: "30px", height: "30px" }}
              alt="farming"
            />
          </a>
        </li>
        <li>
          <a href="https://t.me/joinchat/7Eez239jl_thOTM0" className="social-link">
            <i className="fa fa-telegram" aria-hidden="true"></i>
          </a>
        </li>
        <li>
          <a href="https://youtube.com/channel/UCRp7acalw10Bq6dv7x8qjBg" className="social-link">
            <i className="fa fa-youtube" aria-hidden="true"></i>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/SocietyAncient?s=08" className="social-link">
            {" "}
            <i className="fa fa-twitter" aria-hidden="true"></i>
          </a>
        </li>
        <li>
          <a href="https://web.facebook.com/groups/3119395951622152/" className="social-link">
            <i className="fa fa-facebook" aria-hidden="true"></i>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default CryptoVerticalSocialComponent;
