import { Deployments } from "./deployments";

export type Configuration = {
  chainId: number;
  networkName: string;
  etherscanUrl: string;
  defaultProvider: string;
  deployments: Deployments;
  externalTokens: { [contractName: string]: [string, number] };
  config?: EthereumConfig;
  refreshInterval: number;
};

export type EthereumConfig = {
  testing: boolean;
  autoGasMultiplier: number;
  defaultConfirmations: number;
  defaultGas: string;
  defaultGasPrice: string;
  bscNodeTimeout: number;
};

export type TokenInfo = {
  address: string;
  decimal: number;
};

export const defaultEthereumConfig = {
  testing: false,
  autoGasMultiplier: 1.5,
  defaultConfirmations: 1,
  defaultGas: "6000000",
  defaultGasPrice: "1000000000000",
  bscNodeTimeout: 10000,
};

export const ServerConfig = {
  url: "https://ancientscoin.com/cash/"
};

export const tokenInfo = {
  address: "0x6a2e100bb4e6b9a12386483ef717ff388151c0c8",
  ts: 1000000000000000000,
  decimals: 9,
  symbol: "LioE",
  presaleAddress: "0xe793b769B6D0c6E62AD4e5A3B2B2c05D23CbdD1F",
  presaleAmount: 100000000000000000
};

export const generalInfo = {
  pcsRouster: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
  wbnbAddr: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  usdAddr: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  routerAbi: [
        {
        "type":"function",
        "stateMutability":"view",
        "outputs":[
            {
            "type":"uint256[]",
            "name":"amounts",
            "internalType":"uint256[]"
            }
        ],
        "name":"getAmountsOut",
        "inputs":[
            {
            "type":"uint256",
            "name":"amountIn",
            "internalType":"uint256"
            },{
            "type":"address[]",
            "name":"path",
            "internalType":"address[]"
            }
        ]
        }
    ],
}

export const PresaleAbi = [
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "beneficiary",
              "type": "address"
          }
      ],
      "name": "buyTokens",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType":"address",
              "name":"beneficiary",
              "type":"address"
          }, {
              "internalType":"address",
              "name":"referral",
              "type":"address"
            }
        ],
        "name":"buyTokensByInvite",
        "outputs":[],
        "stateMutability":"payable",
        "type":"function"
    },
    {
      "inputs": [
          {
              "internalType": "address",
              "name": "",
              "type": "address"
          }
      ],
      "name": "holders",
      "outputs": [
          {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "maxAmountToBuyPerTransaction",
      "outputs": [
          {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "maxAmountToSell",
      "outputs": [
          {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "rate",
      "outputs": [
          {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "sellAmount",
      "outputs": [
          {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "sellStatus",
      "outputs": [
          {
              "internalType": "bool",
              "name": "",
              "type": "bool"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "token",
      "outputs": [
          {
              "internalType": "contract IERC20",
              "name": "",
              "type": "address"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "weiRaised",
      "outputs": [
          {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "stateMutability": "payable",
      "type": "receive"
  }
]