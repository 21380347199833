import EditIcon from '@material-ui/icons/Edit';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import React, {useEffect, useState } from 'react';
import { Button, Input } from 'antd';
import Avatar from 'react-avatar';
import axios from 'axios';
import {ServerConfig} from '../../ancient-finance/config'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { MdContentCopy } from "react-icons/md";

import './ShowAccountInfo.css';

const SERVER_URL = ServerConfig.url;

const ShowProfileInfo: React.FC<{
  setIsEditAble: Function;
  setIsChangePassword: Function;
}> = ({ setIsEditAble, setIsChangePassword }) => {
  const [aboutMe, setAboutMe] = useState("")
  const [edit, setEdit] = useState(false);
  const [membership, setMembership] = useState([])
  let credentialObj = JSON.parse(localStorage.getItem("login"))
  //let apiKey = localStorage.getItem('apiKey')

  const getLocaleDate = (date: any) => {
    const dateObj = new Date(date);
    let localeDate = dateObj.toLocaleDateString();
    return localeDate
  }

  useEffect(() => {
    axios.post(SERVER_URL + "aboutme", {userName: credentialObj.userName, pwd: credentialObj.pwd})
    .then(res => {
      if (res.data.status) {
        setAboutMe(res.data.message.aboutMe)
      }
    }).catch(err => {
      console.log(err)
    })

    axios.get(SERVER_URL + "membership")
    .then(res => setMembership(res.data.message))
  }, [])

  return (
    <div className='ShowProfileInfo '>
      <div className='ShowProfileInfo__container'>
        <div className='row no-gutters' style={{display: "flex", justifyContent: "space-between"}}>
          <div className='col-sm-8'>
            <div>
              {/* <div className='ShowProfileInfo__nameSection'> */}
              <div>
                <span className='position-relative h2'>
                  {credentialObj.fullName}
                </span>
                <span className='badge badge-primary badge-pill ShowProfileInfo__badge' style={{display: "inline-block"}}>
                  {membership[credentialObj.membership]}
                </span>
                {/* <div className='badge badge-primary badge-pill ShowProfileInfo__badge' style={{display: "inline-block"}}>
                  11111111111{membership[credentialObj.membership]}
                </div> */}
              </div>
            </div>
          </div>
          <div>
            <button
              className='btn btn-primary px-2 py-1 ml-2 btn-sm float-right'
              onClick={() => {
                setIsEditAble(false);
                setIsChangePassword(true);
              }}
            >
              <VpnKeyIcon />
            </button>
            <button
              className='btn btn-primary btn-sm px-2 py-1 float-right'
              onClick={() => {
                setEdit(true);
              }}
            >
              <EditIcon />
            </button>
          </div>
        </div>
        <div style={{marginBottom: 15}}>
          <Avatar name={credentialObj.userName} size="100" round={true}/>
          {/* <Button type="primary" style={{marginLeft: 15}}>Change</Button> */}
        </div>
        {/* <p className='text-muted text-primary' style={{marginTop: 10}}>{credentialObj.userName}</p> */}
        {/* <div style={{display: "flex", justifyContent: "space-between"}}>
          <p className='text-dark referral-link'>
            {window.location.origin + '/referral/' + credentialObj.fullName}
            &nbsp;
          </p>
          <CopyToClipboard text={window.location.origin + '/referral/' + credentialObj.fullName}><div style={{cursor: "pointer"}}><MdContentCopy/>&nbsp;&nbsp;Referral Link</div></CopyToClipboard>
        </div> */}
        <div className="mb-4">
          Full Name
          <Input placeholder="Basic usage" value={credentialObj.fullName} readOnly={!edit}/>
        </div>
        <div className="mb-4">
          Your Referral Name
          <Input placeholder="Basic usage" value={credentialObj.referralUser} readOnly={!edit}/>
        </div>
        <div className="mb-4">
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <div>Email Address</div><div>{credentialObj.active ? "verified" : "not verified"}</div>
          </div>
          <Input placeholder="Basic usage" value={credentialObj.emailAddr} readOnly={!edit}/>
        </div>
        <div className="mb-4">
          Created At
          <Input placeholder="Basic usage" value={getLocaleDate(credentialObj.date)} readOnly/>
        </div>
        <div className="mb-4">
          Country
          <Input placeholder="Basic usage" value={credentialObj.country} readOnly/>
        </div>
        <div className="mb-4">
          Ranking
          <Input placeholder="Basic usage" value={membership[credentialObj.membership]} readOnly/>
        </div>
        {edit && <div className='text-right'>
          <button
            type='button'
            className='btn btn-danger btn-sm mr-2'
            onClick={() => setEdit(false)}
          >
            Cancel
          </button>
          <button type='submit' className='btn btn-primary btn-sm'>
            Save
          </button>
        </div>}
      </div>
    </div>
  );
};

export default ShowProfileInfo;
