import React, { useState, useEffect, useContext } from "react";
import { Modal, Slider, Spin, Input, notification } from "antd";
import axios from 'axios';
import Particles from "react-particles-js";
import { useEthers } from "@usedapp/core";
import { SocialIcon } from 'react-social-icons';
import useRefresh from "../../hooks/useRefresh";
import useAncientFinance from "../../hooks/useCsdFinance";
import useAddToken from "../../hooks/useAddToken";
import countryList from 'react-select-country-list'
import "./style.css";
import IMAGE1 from "../../assets/gallery/image-1.jpg"
import IMAGE2 from "../../assets/gallery/image-2.jpg"
import IMAGE10 from "../../assets/gallery/image-10.jpg"
import IMAGE14 from "../../assets/gallery/image-14.jpg"
import { exists } from "fs";
import {ServerConfig} from '../../ancient-finance/config'
const SERVER_URL = ServerConfig.url;

const marks = {
  0: "0%",
  250000000: "25%",
  500000000: "50%",
  750000000: "75%",
  1000000000: {
    style: {
      color: "#f50",
    },
    label: <strong>100%</strong>,
  },
};

export default () => {
  const { slowRefresh, fastRefresh } = useRefresh();
  const { account } = useEthers();
  const AncientFinance = useAncientFinance();
  const [csdTokenAddress, setCsdTokenAddress] = useState("");
  const [csdCrowdSaleAddress, setCsdCrowdSaleAddress] = useState("");
  const [totalCountries, setTotalCountries] = useState(0);
  const [totalMembership, setTotalMembership] = useState(0);
  const [allCountryInfo, setAllCountryInfo] = useState([])

  const { onHandleAddToken } = useAddToken();
  const [rate, setRate] = useState(0);
  const [sellAmount, SetSellAmount] = useState(0);
  const [totalAmount, SetTotalAmount] = useState(0);
  const [maxAmountToBuyPerTransaction, SetMaxAmountToBuyPerTransaction] = useState(0);
  const [maxAmountToSell, SetMaxAmountToSell] = useState(0);
  const [ModalVisible, SetModalVisible] = useState(false);
  const [Inputval, SetInputval] = useState(0);
  const [sellPercent, setSellPercent] = useState(0);
  const [csdPrice, setCsdPrice] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  const [occurError, setOccurError] = useState(false);
  const [errorContent, setErrorContent] = useState("");

  const [meetingTime, setMeetingTime] = useState({});
  const [startMeetingTime, setStartMeetingTime] = useState("")
  const [endMeetingTime, setEndMeetingTime] = useState("")

  let credentialObj = JSON.parse(localStorage.getItem("login"))
  let apiKey = localStorage.getItem('apiKey')

  function toastMessage(msg, timeout) {
    notification.open({
        message: 'Alert',
        description: msg,
        duration: timeout
    });
  }

  const downloadPrivateGoldSheet = () => {
    axios.post(SERVER_URL + "societyGoldSheet", {pwd: credentialObj.pwd, userName: credentialObj.userName}, {responseType: 'arraybuffer'})
    .then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'THE ANCIENT SOCIETY’S GOLD SHEET.pdf');
      document.body.appendChild(link);
      link.click();
    })
  }

  const attendMeeting = () => {
    axios.post(SERVER_URL + "meetingLink", {pwd: credentialObj.pwd, userName: credentialObj.userName})
    .then(res => {
      if (res.data && res.data.status) {
        window.open(res.data.message, "_blank")
      } else {
        toastMessage(res.data.message, 1.2)  
      }
    }).catch(err => {
      toastMessage("Error Occured attending Meeting", 1.2)
    })
  }

  useEffect(() => {
    axios.get(SERVER_URL + "countryMems")
    .then(res => {
      if (res.data && res.data.status) {
        setTotalCountries(res.data.message.countries)
        setTotalMembership(res.data.message.memberships)
      }
    })

    axios.post(SERVER_URL + "meetingTime", {pwd: credentialObj.pwd, userName: credentialObj.userName})
    .then(res => {
      if (res.data && res.data.status) {
        if (res.data.message.from && res.data.message.to) {
          setMeetingTime(res.data.message);
          let startTime = new Date(res.data.message.from)
          let endTime = new Date(res.data.message.to)
          setStartMeetingTime(startTime.toTimeString())
          setEndMeetingTime(endTime.toTimeString())
        }
      }
    })

    axios.post(SERVER_URL + "allCountryInfo", {pwd: credentialObj.pwd, userName: credentialObj.userName})
    .then(res => {
      if (res.data && res.data.status && res.data.message.length > 0) {
        const update = [...res.data.message].sort(
          function(a, b){return b.totalTokens - a.totalTokens}
        );
        setAllCountryInfo(update)
      }
    })
  }, [])
  useEffect(() => {
    if (AncientFinance) {
      const { CSD, CSDCrowdsale } = AncientFinance.contracts;
      setCsdTokenAddress(CSD.address);
      setCsdCrowdSaleAddress(CSDCrowdsale.address);
    }
  }, [AncientFinance]);

  useEffect(() => {
    if (AncientFinance) {
      AncientFinance.getCsdPrice().then((price) => {
        setCsdPrice(price);
      });
      AncientFinance.getCsdStat().then((stat) => {
        SetSellAmount(stat.sellAmount);
        SetMaxAmountToBuyPerTransaction(stat.maxAmountToBuyPerTransaction);
        SetMaxAmountToSell(stat.maxAmountToSell);
        SetTotalAmount(stat.totalAmount);
        setSellPercent(stat.sellPercent);
      });
    }
  }, [AncientFinance]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "theme-assets/js/swiperloader.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleChange = (value) => {
    if (typeof value == "object") {
      if (value.target.value == null) {
        SetInputval(0);
      } else {
        SetInputval(value.target.value);
      }
    } else {
      SetInputval(value);
    }
  };

  const showModal = () => {
    SetModalVisible(true);
  };

  const csdRemaining = () => {
    let result = totalAmount - sellAmount;
    return result;
  };

  return (
    <main>
      {/*	Information */}
      <section
        id="information"
        className="token-distribution section-padding"
      >
        <div className="container">
          <div className="heading text-center">
            <h6 className="sub-title">Information</h6>
            <h2 className="title">Information</h2>
          </div>
          <div className="row">
            <div className="col-lg-5 col-md-12"  style={{display: "flex", alignItems: "center"}}>
              <div className="whitepaper-img">
                <img
                  className="img-fluid animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.3s"
                  src="theme-assets/images/what-is-crypto.png"
                  alt="whatiscrypto"
                />
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="content-area">
                <h4
                  className="title animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.2s"
                >
                  Ancient Society Gold Sheet
                </h4>
                <p
                  className="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.2s"
                >
                  <a
                    className="btn btn-lg btn-gradient-purple btn-glow mb-2 animated"
                    data-animation="fadeInUpShorter"
                    data-animation-delay="1s"
                    style={{marginTop: 10}}
                    onClick={downloadPrivateGoldSheet}
                  >
                    <span className="icon ti-download mr-1"></span>
                    Gold Sheet
                  </a>
                </p>
                <h4
                  className="title animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.2s"
                >
                  Our Mission
                </h4>
                <p
                  className="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.2s"
                >
                  Our Vision sees a world free of limitation and our Mission is to be part of the solution that makes it so.
                </p>
                <h4
                  className="title animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.2s"
                >
                  Private Contract
                </h4>
                <p
                  className="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.2s"
                >
                  The first top holder will Join the Society’s executives Board, 
                  the top 3 will join the Society’s Legislative Board, 
                  the first 7 will join the Country’s Mall executives, 
                  the first 100 shall be share holders and given the privilege 
                  to bid for any of his country’s community projects leadership position
                </p>
                <h4
                  className="title animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.5s"
                >
                  Updates from the Administration
                </h4>
                <p
                  className="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.6s"
                >
                  LioEagle has established a more fair and transparent reginal Leadership 
                  whereby the first 100 highest token holders are selected as leaders to 
                  represent their members in the community. That means everyone in the world 
                  can be a leader in this community.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/ Information */}
      {/* Roadmap */}
      <section id="roadmap" className="roadmap section-padding" style={{paddingTop: 0, paddingBottom: 0}}>
        <div className="container">
          <div
            className="row animated"
            data-animation="fadeInUpShorter"
            data-animation-delay="0.6s"
          >
            <div className="col-12">
              <div className="roadmap-container">
                <div className="swiper-container">
                  <div className="swiper-wrapper timeline">
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp completed">
                          <span className="date">September 2019</span>
                        </div>
                        <div className="status completed">
                          <span>
                            Research & Concept development 
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp completed">
                          <span className="date">June 2021</span>
                        </div>
                        <div className="status completed">
                          <span>
                            Our Private Social Media 
                            <br/>(Ancient Media) Registration and Creation 
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp completed">
                          <span className="date">August 2021</span>
                        </div>
                        <div className="status completed">
                          <span>
                            Website Registration & Creation
                            <br/>Ancient Society partnership
                           </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp completed">
                          <span className="date">September 2021</span>
                        </div>
                        <div className="status completed">
                          <span>
                            Token Design & creation 
                            <br />
                            PancakeSwap listing <br />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp completed">
                          <span className="date">October 2021</span>
                        </div>
                        <div className="status active">
                          <span>Further ecosystem development
                            <br/>Ancient media (ancientsocial.com) initial Lunch and Testing
                            </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide active">
                      <div className="roadmap-info">
                        <div className="timestamp remaining">
                          <span className="date">November 1st 2021</span>
                        </div>
                        <div className="status remaining">
                          <span>
                            1st LioE CrowdSale <br />{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp remaining">
                          <span className="date">November 15th 2021</span>
                        </div>
                        <div className="status remaining">
                          <span>
                            LioE Public Sales<br />{" "}
                          </span>
                          <span>
                            Social Media application Lunch (Play Store) 
                            <br />{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp remaining">
                          <span className="date">November 22nd 2021</span>
                        </div>
                        <div className="status remaining">
                          <span>
                            Farming and Staking Platform Lunched 
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp remaining">
                          <span className="date">November 22nd 2021</span>
                        </div>
                        <div className="status remaining">
                          <span>
                            Social Media OS Application Lunched (Appstore)
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp remaining">
                          <span className="date">December 6th 2021</span>
                        </div>
                        <div className="status remaining">
                          <span>
                            Official lunch of LioEagle Ecosystem
                            <br />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp remaining">
                          <span className="date">January 4th 2022</span>
                        </div>
                        <div className="status remaining">
                          <span>
                            Online Shopping Mall Software Development start
                            <br />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp remaining">
                          <span className="date">March 7th  2022</span>
                        </div>
                        <div className="status remaining">
                          <span>
                            Online Shopping Mall Testing 
                            <br />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp remaining">
                          <span className="date">April 4th 2022</span>
                        </div>
                        <div className="status remaining">
                          <span>
                            First batch of leadership selected and endorsed 
                            <br />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp remaining">
                          <span className="date">April 11th 2022</span>
                        </div>
                        <div className="status remaining">
                          <span>
                            Compilation and Drafting of the Societies Private Contract Association begins 
                            <br />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp remaining">
                          <span className="date">April 18th 2022</span>
                        </div>
                        <div className="status remaining">
                          <span>
                            Smartswap Methodology Initiation begins 
                            <br />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp remaining">
                          <span className="date">May 1th 2022</span>
                        </div>
                        <div className="status remaining">
                          <span>
                            Public LioEagle to Ancient Coin Swapping 
                            <br />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp remaining">
                          <span className="date">May 2nd 2022</span>
                        </div>
                        <div className="status remaining">
                          <span>
                            Private Blockchain Lunched
                            <br />
                            LioEagle Smartswap testing 
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp remaining">
                          <span className="date">August 22nd 2022</span>
                        </div>
                        <div className="status remaining">
                          <span>
                            First Shopping Mall Lunched 
                            <br />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp remaining">
                          <span className="date">September 22nd 2022</span>
                        </div>
                        <div className="status remaining">
                          <span>
                            Internal Exchange Software Development start 
                            <br />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp remaining">
                          <span className="date">November 1st 1011</span>
                        </div>
                        <div className="status remaining">
                          <span>
                            Official Lunch of Ancient Society Globally 
                            <br />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp remaining">
                          <span className="date">November 7th 2022</span>
                        </div>
                        <div className="status remaining">
                          <span>
                            Internal Exchange Testing  
                            <br />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp remaining">
                          <span className="date">5th December 2022</span>
                        </div>
                        <div className="status remaining">
                          <span>
                            Internal Exchange Lunch 
                            <br />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-control">
                  <span className="prev-slide"></span>
                  <span className="next-slide"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/ Roadmap */}
      {/* Total Countries and Membership */}
      <section id="totalcountries" className="whitepaper section-padding">
        <div className="container">
        <div className="heading text-center">
            <h2 className="title">Total Countries and Membership</h2>
            <h6 className="sub-title">Total Countries</h6>
          </div>
          <div className="row">
            <div className="col-lg-5 col-md-12"  style={{display: "flex", alignItems: "center"}}>
              <div className="whitepaper-img">
                <img
                  className="img-fluid animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.3s"
                  src="theme-assets/images/worldmap.png"
                  alt="worldmap"
                />
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="content-area">
                <h4
                  className="title animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.2s"
                >
                  Total Number of Countries
                </h4>
                <p
                  className="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.2s"
                >
                  We provide customers from {totalCountries}+ Countries with our service
                </p>
                <h4
                  className="title animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.2s"
                >
                  Total Membership
                </h4>
                <p
                  className="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.2s"
                >
                  There are {totalMembership} members all over the world
                </p>
              </div>
            </div>
          </div>
          {(allCountryInfo.length > 0) && <div className="row team-intro justify-content-center text-center">
            <table className="countries-table">
              <tr>
                <th>Ranking</th>
                <th>Country</th> 
                <th>Members</th>
                <th>Holding Tokens</th>
              </tr>
              {allCountryInfo.map((e, index) => {
                return <tr id={index} key={index}>
                  <td>{index + 1}</td>
                  <td>{countryList().getLabel(e._id) || "unknown"}</td>
                  <td>{e.members}</td>
                  <td>{e.totalTokens}</td>
                </tr>
              })}
            </table>
              {/* <table style={{width: "80%", color: "white"}}>
                <tr>
                  <th>Ranking</th>
                  <th>Country</th>
                  <th>Total Memberships</th> 
                  <th>Holding Number</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>US</td>
                  <td style={{wordBreak: "break-all"}}>Vitaliy111111111111111111111111</td>
                  <td style={{wordBreak: "break-all"}}>1</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>US</td>
                  <td>Vitaliy</td>
                  <td>1010010</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>US</td>
                  <td>Vitaliy</td>
                  <td>1010010</td>
                </tr>
              </table> */}
          </div>}
        </div>
      </section>
      {/*/ Total Countries and Membership */}
      {/* Media */}
      <section id="media" className="team section-padding ">
        <div className="container">
          <div className="heading text-center">
            <div
              className="animated"
              data-animation="fadeInUpShorter"
              data-animation-delay="0.3s"
            >
              <h6 className="sub-title">Media</h6>
              <h2 className="title">Media</h2>
            </div>
          </div>
          <div className="content-area">
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="play-video text-center">
                  <span className="mt-2 d-md-block">
                    <h5 className="title">Visit Our Social Media Website</h5>
                    <span>
                      <a href="http://ancientsmedia.com">
                      <img
                        className="img-fluid"
                        src="theme-assets/images/media.png"
                        style={{width:90, height: 90, cursor: "pointer"}}
                        alt="Social media website"
                      />
                      </a>
                    </span>
                  </span>
                </div>
                <p
                  className="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.3s"
                >
                  The Ancient Society is a Virtual community that 
                  aims at uniting Humanity under one umbrella and providing them 
                  with a mechanized means of financial 
                  relieve through the common law powered the Blockchain Technology.
                  <br/>We are a virtual community that never intends to establish a conservative society. 
                  We are bound by the spirit and principles of LIGHT & LOVE for the Human dignity as God’s highest creation 
                </p>
                <div style={{textAlign: "center"}}>
                <span><SocialIcon url="https://t.me/joinchat/7Eez239jl_thOTM0" target="_blank" bgColor="white" style={{width: 32, height: 32, margin: 8}} network="telegram"/></span>
                <span><SocialIcon url="https://web.facebook.com/groups/3119395951622152/" target="_blank" bgColor="white" style={{width: 32, height: 32, margin: 8}}/></span>
                <span><SocialIcon url="https://twitter.com/SocietyAncient?s=08" target="_blank" bgColor="white" style={{width: 32, height: 32, margin: 8}}/></span>
                <span><SocialIcon url="https://join.slack.com/t/ancientsociety/shared_invite/zt-vsmtul82-P3imNvMJnF92na_~7kcvJA" target="_blank" bgColor="white" style={{width: 32, height: 32, margin: 5}}/></span>
                <span><SocialIcon url="https://youtube.com/channel/UCRp7acalw10Bq6dv7x8qjBg" target="_blank" bgColor="white" style={{width: 32, height: 32, margin: 8}}/></span>
                </div>
              </div>

              <div
                className="col-md-12 col-lg-6 animated"
                data-animation="fadeInUpShorter"
                data-animation-delay="0.3s"
              >
                <div className="position-relative what-is-crypto-img float-xl-right">
                  <img
                    className="img-fluid"
                    src={IMAGE14}
                    alt="Social media website"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/ Media */}
      {/* Meeting */}
      <section id="meeting" className="team section-padding ">
        <div className="container">
          <div className="heading text-center">
            <div
              className="animated"
              data-animation="fadeInUpShorter"
              data-animation-delay="0.3s"
            >
              <h6 className="sub-title">Meeting</h6>
              <h2 className="title">Meeting</h2>
            </div>
          </div>
          <div className="content-area">
            <div className="row">
              <div
                className="col-md-12 col-lg-6 animated"
                data-animation="fadeInUpShorter"
                data-animation-delay="0.3s"
              >
                <div className="position-relative what-is-crypto-img float-xl-right">
                  <img
                    className="img-fluid"
                    src="theme-assets/images/meeting.jpg"
                    alt="our meeting"
                  />
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="play-video text-center">
                  <span className="mt-2 d-md-block">
                    <h5 className="title">Welcome to Our Meeting</h5>
                    <span>
                      <img
                        className="img-fluid"
                        src="theme-assets/images/conference.png"
                        style={{width:90, height: 90, cursor: "pointer"}}
                        alt="Social meeting"
                        onClick={showModal}
                      />
                    </span>
                  </span>
                </div>
                <p
                  className="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.3s"
                >
                  <br/>Your participation will encourage us to have more good,  more particular, more popular idea and we will try our best to hear good feedback from the community users.
                  <br/>Anyone is welcome to join our meeting.
                  <br/>Please attend our meeting to develope our ancient communicty.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/ Meeting */}
      {/* ICO Buy Modal */}
      <div
        className="modal ico-modal fade"
        id="ico-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body p-0">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe className="embed-responsive-item" id="video"></iframe>
              </div>
            </div>
          </div>
        </div>
        <Modal
          zIndex={1530}
          className="testtest"
          title="Today Meeting"
          centered
          mask={true}
          visible={ModalVisible}
          onOk={attendMeeting}
          onCancel={() => SetModalVisible(false)}
          okText="Attend"
        >
          <Spin spinning={isPending} tip="Loading...">
            {/* {!active && <p>Connect to MetaMask!</p>} */}
            <p>
              From: {startMeetingTime}
            </p>
            <p>
              To: {endMeetingTime}
            </p>
            {/*<p>Max buy per transaction: {maxAmountToBuyPerTransaction} (one billion)</p>*/}
            {/*<p>Max buy per wallet: {maxAmountToSell} (two billions)</p>*/}
          </Spin>
        </Modal>
      </div>
    </main>
  );
};
