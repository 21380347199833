import React from 'react';
import RecoverPwdContainer from '../../containers/RecoverPwdContainer';
import history from '../../utils/history';

const RecoverPwdPage = (props) => {

  return <RecoverPwdContainer {...props}  />;
};

export default RecoverPwdPage;  
