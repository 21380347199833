import React from 'react';
import GalleryContainer from '../../containers/GalleryContainer';

const GalleryPage = (props) => {
  return (
    <div>
      <GalleryContainer {...props} />
    </div>
  );
};

export default GalleryPage;
