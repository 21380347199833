import React, { useState, useEffect } from 'react';
import { MdContentCopy } from "react-icons/md";
import {useUpdateLogin} from '../../state/application/hooks'
import { updateLogin } from "../../state/application/actions";
import { Redirect } from 'react-router';
import EditProfile from './EditProfile';
import PasswordUpdateComponents from './PasswordUpdateComponents';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ShowAccountInfo from './ShowAccountInfo';

const AccountComponent: React.FC = () => {
  const [isEditAble, setIsEditAble] = useState<boolean>(false);
  const [isChangePassword, setIsChangePassword] = useState<boolean>(false);
  let credentialObj = JSON.parse(localStorage.getItem("login"))
  const updateLoginStatus = useUpdateLogin()

  /*useEffect(() => {
    console.log('receieved updated login', updateLoginStatus)
    if (updateLoginStatus && updateLoginStatus !== "") {
      setLoginStatus(updateLoginStatus)
    }
  }, [updateLoginStatus])*/

  return (
    <div>
      {credentialObj ? <main>
        <section id='contact' className='contact section-padding'>
          <div className='container'>
            <div className='heading text-center'>
              <div
                className='animated'
                data-animation='fadeInUpShorter'
                data-animation-delay='0.3s'
              >
                <h6 className='sub-title'>Ancient Community</h6>
                <h2 className='title'>Account</h2>
              </div>
            </div>

            <div className='row' style={{justifyContent: "center", color: "#a0a0bc"}}>
              <div className='col-md-6' style={{marginTop: 30}}>
                <div className="bg-white">
                  <div className='bg-white p-5' style={{textAlign: "center", wordBreak: "break-all"}}>
                    <CopyToClipboard text={window.location.origin + '/referral/' + credentialObj.userName}><div style={{cursor: "pointer"}}><MdContentCopy/>&nbsp;&nbsp;Copy Referral Link</div></CopyToClipboard>
                    <div className="p-2">
                      <h6>
                        {window.location.origin + '/referral/' + credentialObj.userName}
                      </h6>
                    </div>
                  </div>
                  <hr style={{width: "90%", margin: 0}}/>
                  <div className='bg-white p-5' style={{textAlign: "center", wordBreak: "break-all"}}>
                  <CopyToClipboard text={window.location.origin + '/private/' + "0xC7f448Da8a997741ce8D6d9b3eFF867FCb52887D"}><div style={{cursor: "pointer"}}><MdContentCopy/>&nbsp;&nbsp;Copy Private Blockchain Wallet</div></CopyToClipboard>
                    <div className="p-2">
                      <h6>
                        {window.location.origin + '/private/' + "0xC7f448Da8a997741ce8D6d9b3eFF867FCb52887D"}
                      </h6>
                    </div>
                  </div>
                  <hr style={{width: "90%", margin: 0}}/>
                  <div className='bg-white p-5' style={{textAlign: "center"}}>
                    Total Admins Wallet Balance
                    <div className="p-2">
                      <h6>
                        1000000000LioE
                      </h6>
                    </div>
                  </div>
                  <hr style={{width: "90%", margin: 0}}/>
                  <div className='bg-white p-5' style={{textAlign: "center"}}>
                    Voting(Coming soon)
                  </div>
                </div>
              </div>
              <div className='col-md-6' style={{marginTop: 30}}>
                <div className='bg-white p-5  rounded'>
                  {isEditAble && <EditProfile setIsEditAble={setIsEditAble} />}
                  {!isEditAble && !isChangePassword && (
                    <ShowAccountInfo
                      setIsEditAble={setIsEditAble}
                      setIsChangePassword={setIsChangePassword}
                    />
                  )}
                  {isChangePassword && (
                    <PasswordUpdateComponents
                      setIsChangePassword={setIsChangePassword}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main> :
      <div>
        <Redirect to="/"></Redirect>
      </div>}
    </div>
  );
};

export default AccountComponent;
