import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import countryList from 'react-select-country-list'
import useRefresh from "../../hooks/useRefresh";
import useAncientFinance from "../../hooks/useCsdFinance";
import useAddToken from "../../hooks/useAddToken";
import "./style.css";
import IMAGE2 from "../../assets/gallery/image-2.jpg"
import {ServerConfig} from '../../ancient-finance/config'
const SERVER_URL = ServerConfig.url;

const marks = {
  0: "0%",
  250000000: "25%",
  500000000: "50%",
  750000000: "75%",
  1000000000: {
    style: {
      color: "#f50",
    },
    label: <strong>100%</strong>,
  },
};

export default () => {
  const { slowRefresh, fastRefresh } = useRefresh();
  const AncientFinance = useAncientFinance();
  const [leaderships, setLeaderships] = useState([])
  const [membership, setMembership] = useState([])

  let credentialObj = JSON.parse(localStorage.getItem("login"))
  let apiKey = localStorage.getItem('apiKey')


  useEffect(() => {
    axios.get(SERVER_URL + "membership")
    .then(res => setMembership(res.data.message))
    
    axios.post(SERVER_URL + "leaderships", {pwd: credentialObj.pwd, userName: credentialObj.userName})
    .then(res => {
      if (res.data && res.data.status && res.data.message.length > 0) {
        setLeaderships(res.data.message)
      }
    })
  }, [])


  useEffect(() => {
    const script = document.createElement("script");
    script.src = "theme-assets/js/swiperloader.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <main>
      {/* Societies leadership Board */}
      <section className="about section-padding" id="leadership">
        <div className="container">
          <div className="heading text-center">
            <div
              className="animated"
              data-animation="fadeInUpShorter"
              data-animation-delay="0.3s"
            >
              <h6 className="sub-title">Leadership</h6>
              <h2 className="title">Societies Leadership</h2>
            </div>
          </div>
          <div className="row team-intro justify-content-center text-center">
            <img
              src={IMAGE2}
              alt="top3members"
              style={{width: "60%", marginBottom: 30}}
            />
          </div>
          <div className="row team-intro justify-content-center text-center">
            <table className="leadership-table">
              <tr>
                <th>No</th>
                <th>Ranking</th>
                <th>Name</th> 
                <th>Nationality</th>
                <th>Total Tokens</th>
                <th>Total Meetings</th>
                <th>Total Referrals</th>
                <th>Total Points</th>
              </tr>
              {leaderships.map((e, index) => {
                return <tr id={index} key={index}>
                  <td>{index + 1}</td>
                  <td>{membership[e.role]}</td>
                  <td>{e.fullName}</td>
                  <td>{countryList().getLabel(e.country) || "unknown"}</td>
                  <td>{e.tokensHolding}</td>
                  <td>{e.totalMeeting}</td>
                  <td>{e.totalReferrals}</td>
                  <td>{e.totalPoint}</td>
                </tr>
              })}
            </table>
          </div>
        </div>
      </section>
      {/* Societies leadership Board */}
    </main>
  );
};
