import React from 'react';
import RecoverPwdComponent from '../../components/RecoverPwdComponent';

const RecoverPwdContainer = (props) => {
  return (
    <>
      <RecoverPwdComponent {...props} />
    </>
  );
};

export default RecoverPwdContainer;
