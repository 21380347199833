import React from 'react';
import CryptoLoginComponent from '../../components/CryptoLoginComponent';

const LoginContainer = (props) => {
  return (
    <>
      <CryptoLoginComponent {...props} />
    </>
  );
};

export default LoginContainer;
