import {
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {ServerConfig} from '../../ancient-finance/config'

const SERVER_URL = ServerConfig.url;

function createData(
  name: any,
  calories: any,
  fat: any,
  carbs: any,
  protein: any
) {
  return { name, calories, fat, carbs, protein };
}
const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];
const useStyles = makeStyles({
  tr: {
    boxShadow: 'none',
  },
});

const AllRefersComponent = () => {
  const classes = useStyles();
  const [refers, setRefers] = useState([])
  let credentialObj = JSON.parse(localStorage.getItem("login"))
  let apiKey = localStorage.getItem('apiKey')
  useEffect(() => {
    axios.post(SERVER_URL + "refers", {pwd: credentialObj.pwd, userName: credentialObj.userName})
    .then(res => {
      if (res.data.status) {
        setRefers(res.data.message)
      } else {
        console.log('No refers', res.data.messag)
      }
      
    }).catch(err => {
      console.log('Refer fetch failed', err)
    })
    /*if (refers.length <= 0) {
    }*/
  }, [])


  return (
    <div>
      <div className='bg-white p-5  rounded'>
        <h3 className='text-center'>Your Refers</h3>
        <Divider light />
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell align='center'>Name</TableCell>
              <TableCell align='center'>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {refers.map((e, index) => (
              <TableRow key={index} className={classes.tr}>
                <TableCell component='th' scope='row'>
                  {index + 1}
                </TableCell>
                <TableCell align='left'>{e.fullName}</TableCell>
                <TableCell align='left'>{e.date}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default AllRefersComponent;
