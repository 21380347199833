import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { notification } from 'antd';
import axios from 'axios';
import {ServerConfig} from '../../ancient-finance/config'

const useStyles = makeStyles((theme) => ({
  invalidInput: {
    color: "red"
  }
}));

const SERVER_URL = ServerConfig.url;

const PasswordUpdateComponents: React.FC<{ setIsChangePassword: Function }> = ({
  setIsChangePassword,
}) => {
  const classes = useStyles();

  let credentialObj = JSON.parse(localStorage.getItem("login"))
  let apiKey = credentialObj.randString;

  const [oldPwd, setOldPwd] = useState("")
  const [newPwd, setNewPwd] = useState("")
  const [confirmPwd, setConfirmPwd] = useState("")
  const [validOldPwd, setValidOldPwd] = useState(true)
  const [validNewPwd, setValidNewPwd] = useState(true)
  const [validConfirmPwd, setValidConfirmPwd] = useState(true)

  const onFocusOld = () => {
    if (!validOldPwd) {
      setValidOldPwd(true)
      setOldPwd("")
    }
  }

  const onFocusNew = () => {
    if (!validNewPwd) {
      setValidNewPwd(true)
      setNewPwd("")
    }
  }

  const onFocusConfirm = () => {
    if (!validConfirmPwd) {
      setValidConfirmPwd(true)
      setConfirmPwd("")
    }
  }

  const submitHandler = (event: any) => {
    event.preventDefault();
    setIsChangePassword(false);
  };

  const changePassword = () => {
    if (!oldPwd || !validOldPwd) {
      setOldPwd("*Please Input Old Password")
      setValidOldPwd(false)
      return;
    }

    if (!newPwd || !validNewPwd) {
      setNewPwd("*Please Input New Password")
      setValidNewPwd(false)
      return;
    }

    if (!confirmPwd || !validConfirmPwd) {
      setConfirmPwd("*Please Input Confrim Password")
      setValidConfirmPwd(false)
      return;
    }

    if (confirmPwd !== newPwd) {
      setConfirmPwd("*Not matched Password")
      setValidConfirmPwd(false)
      return;
    }

    axios.post(SERVER_URL + "updatepwd", {userName: credentialObj.userName, pwd: oldPwd, pwdNew: newPwd})
    .then(res => {
      console.log(res.data)
      if (res.data.status) {
        notification.open({
          message: 'Notification',
          description: "Updated Password Successfully",
        });
        setIsChangePassword(false)
      } else {
        notification.open({
          message: 'Notification',
          description: res.data.message,
        });
      }
    }).catch(err => {
      notification.open({
        message: 'Notification',
        description: "Update Password Failed",
      });
    })
  }

  return (
    <div>
      <h2>Change Password</h2>
      <div className='divider'></div>
      <form onSubmit={submitHandler}>
        <input
          type={validOldPwd ? 'password' : 'edit'}
          className={`form-control mb-4 animated ${!validOldPwd && classes.invalidInput}`}
          data-animation='fadeInUpShorter'
          data-animation-delay='0.3s'
          name='oldPassword'
          placeholder='Old Password'
          value={oldPwd}
          maxLength={25}
          onFocus={onFocusOld}
          onChange={(e) => {setOldPwd(e.target.value)}}
        />
        <input
          type={validNewPwd ? 'password' : 'edit'}
          className={`form-control mb-4 animated ${!validNewPwd && classes.invalidInput}`}
          data-animation='fadeInUpShorter'
          data-animation-delay='0.3s'
          name='newPassword'
          placeholder='New Password'
          value={newPwd}
          maxLength={25}
          onFocus={onFocusNew}
          onChange={(e) => {setNewPwd(e.target.value)}}
        />
        <input
          type={validConfirmPwd ? 'password' : 'edit'}
          className={`form-control mb-4 animated ${!validConfirmPwd && classes.invalidInput}`}
          data-animation='fadeInUpShorter'
          data-animation-delay='0.3s'
          name='confirmPassword'
          placeholder='Confirm Password'
          value={confirmPwd}
          maxLength={25}
          onFocus={onFocusConfirm}
          onChange={(e) => {setConfirmPwd(e.target.value)}}
        />

        <div className='text-right'>
          <button
            type='button'
            className='btn btn-danger btn-sm mr-2'
            onClick={() => setIsChangePassword(false)}
          >
            Cancel
          </button>
          <button type='button' className='btn btn-primary btn-sm' onClick={changePassword}>
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default PasswordUpdateComponents;
