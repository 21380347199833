import React from 'react';
import ForgotPwdComponent from '../../components/ForgotPwdComponent';

const ForgotPwdContainer = (props) => {
  return (
    <>
      <ForgotPwdComponent {...props} />
    </>
  );
};

export default ForgotPwdContainer;
