import React, { useState, useEffect } from 'react';
import {
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import axios from 'axios';
import { MdContentCopy } from "react-icons/md";
import {useUpdateLogin} from '../../state/application/hooks'
import { updateLogin } from "../../state/application/actions";
import { Redirect } from 'react-router';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {ServerConfig, tokenInfo} from '../../ancient-finance/config'

const SERVER_URL = ServerConfig.url;

const useStyles = makeStyles({
  tr: {
    boxShadow: 'none',
  },
});

const BonusComponent: React.FC = () => {
  const classes = useStyles();
  let credentialObj = JSON.parse(localStorage.getItem("login"))
  const updateLoginStatus = useUpdateLogin()
  const [bonusList, setBonusList] = useState([])

  /*useEffect(() => {
    console.log('receieved updated login', updateLoginStatus)
    if (updateLoginStatus && updateLoginStatus !== "") {
      setLoginStatus(updateLoginStatus)
    }
  }, [updateLoginStatus])*/
  useEffect(() => {
    if (credentialObj) {
      axios.post(SERVER_URL + "bonus", {userName: credentialObj.userName, pwd: credentialObj.pwd})
      .then(res => {
        if (res.data.status) {
          setBonusList(res.data.message)
        }
        console.log(res.data.messag)
      }).catch(err => {
        console.log(err)
      })
    }
  }, [])
  return (
    <div>
      {credentialObj ? <main>
        <section id='contact' className='contact section-padding'>
          <div className='container'>
            <div className='heading text-center'>
              <div
                className='animated'
                data-animation='fadeInUpShorter'
                data-animation-delay='0.3s'
              >
                <h6 className='sub-title'>Ancient Community</h6>
                <h2 className='title'>Reward Bonus</h2>
              </div>
            </div>

            <div className='row' style={{justifyContent: "center", color: "#a0a0bc"}}>
              <div className='col-md-12' style={{marginTop: 30}}>
                {bonusList.length ?
                <div className="bg-white">
                  <div className='bg-white p-5' style={{height: "100%", overflowX: "auto"}}>
                    <h3 className='text-center'>Reward Bonus</h3>
                      <Divider light />
                      <Table aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell>No.</TableCell>
                            <TableCell align='center'>Name</TableCell>
                            <TableCell align='center'>Buy Date</TableCell>
                            <TableCell align='center'>Buy Amount</TableCell>
                            <TableCell align='center'>Tx Hash</TableCell>
                            <TableCell align='center'>Bonus</TableCell>
                            <TableCell align='center'>Status</TableCell>
                            <TableCell align='center'>Reward Tx Hash</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {bonusList.map((e, index) => (
                            <TableRow key={index} className={classes.tr}>
                              <TableCell component='th' scope='row'>
                                {index + 1}
                              </TableCell>
                              <TableCell align='center'>{e.buyerName}</TableCell>
                              <TableCell align='center'>{(new Date(e.buyTime * 1000)).toDateString()}</TableCell>
                              <TableCell align='center'>{e.takenAmount / Math.pow(10, tokenInfo.decimals)}</TableCell>
                              <TableCell align='center'><a target="_blank" href={`https://bscscan.com/tx/${e.buyTransHash}`}>{e.buyTransHash.substring(0, 7) + "..." + e.buyTransHash.slice(-4)}</a></TableCell>
                              <TableCell align='center'>{e.referralAmount / Math.pow(10, tokenInfo.decimals)}</TableCell>
                              <TableCell align='center'>{e.reward ? "Done" : "Pending"}</TableCell>
                              <TableCell align='center'><a target="_blank" href={`https://bscscan.com/tx/${e.bonusTransHash}`}>{e.bonusTransHash.substring(0, 7) + "..." + e.bonusTransHash.slice(-4)}</a></TableCell>

                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>

                  </div>
                </div>
                : <div className="bg-white" style={{minHeight: "40vh", display: "flex", flexDirection: "row", alignItems: "center"}}>
                  <div className='bg-white p-5' style={{textAlign: "center", wordBreak: "break-all", width: "100%"}}>
                    <div style={{cursor: "pointer"}}>
                      No Reward Bonus That You Can Get.
                      <br/>
                      Please Invite Your Friends To Earn 5% of Purchase.
                    </div>
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </section>
      </main> :
      <div>
        <Redirect to="/"></Redirect>
      </div>}
    </div>
  );
};

export default BonusComponent;
