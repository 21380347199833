import React from 'react';
import GalleryComponent from '../../components/GalleryComponent';

const GalleryContainer = (props) => {
  return (
    <div>
      <GalleryComponent {...props} />
    </div>
  );
};

export default GalleryContainer;
