import { makeStyles } from '@material-ui/core/styles';
import React, {useState} from 'react';
import { useDispatch } from "react-redux";
import { Redirect } from 'react-router';
import axios from 'axios';
import { notification } from 'antd';
import isEmail from 'validator/lib/isEmail';
import {ServerConfig} from '../../ancient-finance/config'
import { updateLogin, updateWallet } from "../../state/application/actions";

const useStyles = makeStyles((theme) => ({
  form: {
    boxShadow: '1px 1px 1px 10px #0001',
    background: '#fff',
    borderRadius: '5px',
    padding: '50px 30px',
  },
  invalidInput: {
    color: "red"
  }
}));


const SERVER_URL = ServerConfig.url;

const CryptoLoginComponent = () => {
  const classes = useStyles();
  const [nameOrMail, setNameOrMail] = useState('')
  const [password, setPassword] = useState('')
  const [validName, setValidName] = useState(true)
  const [validPwd, setValidPwd] = useState(true)
  const [loginStatus, setLoginStatus] = useState('')
  const dispatch = useDispatch();
  let credentialObj = JSON.parse(localStorage.getItem("login"))

  function toastMessage(msg, timeout) {
      notification.open({
          message: 'Alert',
          description: msg,
          duration: timeout
      });
      /*setMessage(msg);
      setShowMessage(true);
      setTimeout(() => {
          setShowMessage(false)
      }, timeout);*/
  }

  const login = (e) => {
    e.preventDefault();
    
    if (!nameOrMail || !validName) {
      setNameOrMail("*Please fill out this field")
      setValidName(false);
      return;
    }

    if (!password || !validPwd) {
      setPassword("*Please fill out this field")
      setValidPwd(false);
      return;
    }

    let credential = !isEmail(nameOrMail) ? {userName: nameOrMail.trim(), pwd: password} : {emailAddr: nameOrMail.trim(), pwd: password}
    console.log(credential)
    axios.post(SERVER_URL + "pwdSignin", credential)
    .then((res) => {
      if (res.data) {
        console.log(res.data)
        if (res.data.status) {
          localStorage.setItem('login', JSON.stringify(res.data.message.user))
          toastMessage("Login Successfully", 1.2)
          setLoginStatus(res.data.message.user.userName)
          dispatch(
              updateWallet({
                  wallet: "Connected"
              })
          )
          dispatch(
            updateLogin({
              userName: res.data.message.user.userName
            })
          )
          localStorage.setItem("login", JSON.stringify(res.data.message.user))
          localStorage.setItem('pwdLogin', 'connected')
        } else if (!res.data.status) {
          toastMessage(res.data.message, 1.2)
        }
      }
    }).catch((err) => {
      toastMessage("Login Failed", 1.2)
    });

    return;
  }

  const onFocusName = () => {
    if (!validName) {
      setValidName(true)
      setNameOrMail("")
    }
  }

  const onFocusPwd = () => {
    if (!validPwd) {
      setValidPwd(true)
      setPassword("")
    }
  }

  return (
    <main>
      {!credentialObj ? <section id='contact' className='contact section-padding'>
        <div className='container'>
          <div className='heading text-center'>
            <div
              className='animated'
              data-animation='fadeInUpShorter'
              data-animation-delay='0.3s'
            >
              <h6 className='sub-title'>Ancient Community</h6>
              <h2 className='title'>Login</h2>
            </div>
          </div>

          <div className='row'>
            <div className='col-xl-6 col-md-12 mx-auto'>
              <form
                action='#'
                method='get'
                acceptCharset='utf-8'
                className={`text-center ${classes.form}`}
              >
                <input
                  type='text'
                  className={`form-control mb-4 animated ${!validName && classes.invalidInput}`}
                  data-animation='fadeInUpShorter'
                  data-animation-delay='0.3s'
                  name='userName'
                  placeholder='Username or email'
                  onFocus={onFocusName}
                  value={nameOrMail}
                  onChange={(e) => {setNameOrMail(e.target.value)}}
                  maxLength={40}
                />

                <input
                  type={validPwd ? 'password' : 'edit'}
                  className={`form-control mb-5 animated ${!validPwd && classes.invalidInput}`}
                  data-animation='fadeInUpShorter'
                  data-animation-delay='0.3s'
                  name='password1'
                  placeholder='Password'
                  onFocus={onFocusPwd}
                  value={password}
                  onChange={(e) => {setPassword(e.target.value)}}
                  maxLength={25}
                />
                <div className='row align-items-center my-5'>
                  <div className='col-md-6'>
                    <div class='custom-control custom-checkbox text-left'>
                      <input
                        type='checkbox'
                        class='custom-control-input'
                        id='customCheck1'
                      />
                      <label class='custom-control-label' for='customCheck1'>
                        Remember me
                      </label>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <a href='forgot'>Forgot Password</a>
                  </div>
                </div>

                <button
                  type='submit'
                  className='btn btn-lg btn-gradient-purple animated'
                  data-animation='fadeInUpShorter'
                  data-animation-delay='0.3s'
                  style={{ boxShadow: '0 0 6px 0px #9e48cd' }}
                  onClick={login}
                >
                  Login
                </button>
                <br />
                <span className='h6 mt-4 d-inline-block text-muted'>Or</span>
                <div className='mt-2'>
                  <a href='/register' className='text-dark'>
                    Create a new account
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section> :
      <Redirect to="/"></Redirect>}
    </main>
  );
};

export default CryptoLoginComponent;
